import { useState, useEffect } from 'react';
import domtoimage from 'dom-to-image';
import { TilesType, BackupData } from 'types';
import { AssumptionsState } from 'store/slices/assumptions/assumptionsStore';
import { SkuState } from '../store/slices/sku/sku.types';
import { SourceType } from '../store/slices/sources/sources.types';

export function usePdfData(
    tilesData: Array<TilesType>,
    sources: Array<SourceType>,
    tcoYear: number,
    sku: SkuState,
    assumptions: AssumptionsState,
    graphCompleted: boolean,
    backupData: BackupData,
    backupACV: Array<number>,
    effectivePrice: Array<number>,
    tabIndex: number,
    timeRange: number,
    changeGraphCompleted: React.Dispatch<React.SetStateAction<boolean>>,
    changeBackupData: React.Dispatch<React.SetStateAction<BackupData>>,
    changeBackupACV: React.Dispatch<React.SetStateAction<number[]>>,
    changePdfReady: React.Dispatch<React.SetStateAction<boolean>>,
    changeTimeRangeInSummaryGraph: React.Dispatch<React.SetStateAction<number>>,
): { pdfDialogReady: boolean; summaryGraphUrl: string } {
    const [summaryGraphUrl, changeSummaryGraphUrl] = useState<string>('');
    const [pdfDialogReady, changePdfDialogReady] = useState<boolean>(false);

    useEffect(() => {
        changePdfDialogReady(false);
        changeGraphCompleted(false);
        changeBackupData(null);
        changeBackupACV(null);
    }, [
        sku.discounts,
        sku.plan,
        sources,
        assumptions.changeRates,
        assumptions.dedupe,
        assumptions.ltr,
        assumptions.pricing,
        changeGraphCompleted,
        changeBackupData,
        changeBackupACV,
    ]);

    useEffect(() => {
        if (graphCompleted && !assumptions.isOpen) {
            changeSummaryGraphUrl('');
            // TODO: change it to not use delays; different pdf or graph library would probably be needed
            setTimeout(() => {
                const node = document.querySelector('.summary-graph-for-pdf .recharts-wrapper');
                domtoimage.toPng(node, { style: { visibility: 'visible' } }).then((dataUrl: string) => {
                    changeSummaryGraphUrl(dataUrl);
                    changePdfReady(true);
                });
            }, 100);
        }
    }, [timeRange, changePdfReady, graphCompleted, assumptions.isOpen, tabIndex]);

    useEffect(() => {
        if (summaryGraphUrl === '') changeTimeRangeInSummaryGraph(timeRange);
        else changeTimeRangeInSummaryGraph(tcoYear);
    });

    useEffect(() => {
        if (backupData && summaryGraphUrl && backupACV && effectivePrice && tilesData && graphCompleted)
            changePdfDialogReady(true);
        else changePdfDialogReady(false);
    }, [backupData, summaryGraphUrl, backupACV, effectivePrice, tilesData, graphCompleted]);

    return { pdfDialogReady, summaryGraphUrl };
}
