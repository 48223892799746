import * as React from 'react';
import _ from 'lodash';
import {
    Radio,
    RadioGroup,
    Button,
    FormControlLabel,
    FormControl,
    FormLabel,
    DialogTitle,
    Dialog,
} from '@material-ui/core';
import { RadioButtonUnchecked, RadioButtonChecked, Close } from '@material-ui/icons';
import { confirmButton, confirmCancelButton, confirmButtonDisabled } from 'components/Button/styles';
import { usePdfDataContext } from 'config/context/pdfDataContext';
import { PDFDownloadLink } from '@react-pdf/renderer';
import DocumentLayout from 'pdf/DocumentLayout';
import './PDFDialog.scss';
import { useRootState } from 'store/StateProvider';
import { YEARS_LABEL_ARRAY } from 'config/setup/constants';

type Props = {
    isOpen: boolean;
    summaryGraphUrl: string;
    sources;
    tcoYear: number;
    backupData;
    sku;
    backupACV;
    tilesData;
    timeRangeString: string;
    changeTimeRangeString: React.Dispatch<React.SetStateAction<string>>;
    priceInformationString: string;
    changePriceInformationString: React.Dispatch<React.SetStateAction<string>>;
    pdfReady: boolean;
    handleClose: () => void;
    isCustomerInfoVisibleString: string;
    changeIsCustomerInfoVisibleString: React.Dispatch<React.SetStateAction<string>>;
};

const PDFDialog = ({
    isOpen,
    summaryGraphUrl,
    sources,
    tcoYear,
    backupData,
    sku,
    backupACV,
    tilesData,
    handleClose,
    timeRangeString,
    changeTimeRangeString,
    priceInformationString,
    changePriceInformationString,
    pdfReady,
    isCustomerInfoVisibleString,
    changeIsCustomerInfoVisibleString,
}: Props): JSX.Element => {
    const { timeRange, priceInformation, effectivePrice, isCustomerInfoVisible } = usePdfDataContext();
    const {
        location: { isDell },
        sourcesData: { customerDetails },
    } = useRootState();

    const PDFDocumentLayout = (
        <DocumentLayout
            summaryGraphSrc={summaryGraphUrl}
            sources={sources}
            backupData={backupData}
            sku={sku}
            backupACV={backupACV}
            effectivePrice={effectivePrice}
            tilesData={tilesData}
            timeRange={timeRange}
            priceInformation={priceInformation}
            isDell={isDell}
            customerDetails={customerDetails}
            showCustomerInfo={isCustomerInfoVisible}
        />
    );

    return (
        <Dialog
            open={isOpen}
            aria-labelledby="dialog-title"
            aria-describedby="dialog-description"
            classes={{
                container: 'dialog',
            }}
        >
            <div className="export-dialog">
                <DialogTitle id="dialog-title">What do you want to export?</DialogTitle>
                <Close className="dialog-close-icon" color="disabled" onClick={handleClose} />
                <div className="export-dialog-content">
                    <span>Select variables saved to your PDF</span>
                    <FormControl>
                        <FormLabel>Show Customer Details</FormLabel>
                        <RadioGroup
                            value={isCustomerInfoVisibleString}
                            onChange={(e) => changeIsCustomerInfoVisibleString(e.target.value)}
                            row
                        >
                            <FormControlLabel
                                control={
                                    <Radio
                                        icon={<RadioButtonUnchecked fontSize="large" color="disabled" />}
                                        checkedIcon={<RadioButtonChecked fontSize="large" />}
                                    />
                                }
                                label="Yes"
                                value="yes"
                            />
                            <FormControlLabel
                                control={
                                    <Radio
                                        icon={<RadioButtonUnchecked fontSize="large" color="disabled" />}
                                        checkedIcon={<RadioButtonChecked fontSize="large" />}
                                    />
                                }
                                label="No"
                                value="no"
                            />
                        </RadioGroup>
                    </FormControl>
                    <FormControl>
                        <FormLabel>Time range</FormLabel>
                        <RadioGroup
                            className="flexDirectionRow"
                            value={timeRangeString}
                            onChange={(e) => changeTimeRangeString(e.target.value)}
                        >
                            {_.range(tcoYear).map((index) => (
                                <FormControlLabel
                                    key={index + 1}
                                    disabled={summaryGraphUrl === ''}
                                    control={
                                        <Radio
                                            icon={<RadioButtonUnchecked fontSize="large" color="disabled" />}
                                            checkedIcon={<RadioButtonChecked fontSize="large" />}
                                        />
                                    }
                                    label={YEARS_LABEL_ARRAY[index]}
                                    value={`${index + 1}`}
                                />
                            ))}
                        </RadioGroup>
                    </FormControl>
                    {!isDell && (
                        <FormControl>
                            <FormLabel>Price information</FormLabel>
                            <RadioGroup
                                value={priceInformationString}
                                onChange={(e) => changePriceInformationString(e.target.value)}
                                row
                            >
                                <FormControlLabel
                                    disabled={summaryGraphUrl === ''}
                                    control={
                                        <Radio
                                            icon={<RadioButtonUnchecked fontSize="large" color="disabled" />}
                                            checkedIcon={<RadioButtonChecked fontSize="large" />}
                                        />
                                    }
                                    label="Enabled"
                                    value="enabled"
                                />
                                <FormControlLabel
                                    disabled={summaryGraphUrl === ''}
                                    control={
                                        <Radio
                                            icon={<RadioButtonUnchecked fontSize="large" color="disabled" />}
                                            checkedIcon={<RadioButtonChecked fontSize="large" />}
                                        />
                                    }
                                    label="Disabled"
                                    value="disabled"
                                />
                            </RadioGroup>
                        </FormControl>
                    )}
                </div>
                <div className="export-dialog-buttons">
                    <Button style={confirmCancelButton} onClick={handleClose}>
                        Cancel
                    </Button>
                    {pdfReady ? (
                        <PDFDownloadLink document={PDFDocumentLayout} fileName="results.pdf">
                            <Button style={confirmButton}>Export</Button>
                        </PDFDownloadLink>
                    ) : (
                        <Button style={confirmButtonDisabled}>Export</Button>
                    )}
                </div>
            </div>
        </Dialog>
    );
};

export default PDFDialog;
