import React, { useState, useEffect, useCallback } from 'react';
import Table from 'generic/Table';
import './sourceTable.scss';
import Button from 'components/Button/Button';
import { SourceButton, ActiveSourceButton } from 'components/Button/styles';
import Navigation from 'views/ProgressGraphs/Navigation/Navigation';
import { useRootState } from 'store/StateProvider';
import { capitalize, replace } from 'lodash';
import { WorkloadTypesEnum } from 'types';
import { workloadTypeAliases } from 'utils/utils';
import { columns } from './columns';

const useSourceTable = () => {
    const {
        sourcesData: { data },
    } = useRootState();
    const [tableData, setTableData] = useState([]);
    const [activeSourceIndex, setActiveSourceIndex] = useState(0);

    useEffect(() => {
        if (data) {
            setTableData(
                data.results[`source-${activeSourceIndex + 1}`].map((obj, index) => ({
                    ...obj,
                    month: index + 1,
                    year: Math.ceil((index + 1) / 12),
                })),
            );
        }
    }, [data, activeSourceIndex]);
    return {
        tableData,
        activeSourceIndex,
        setActiveSourceIndex,
    };
};
const SourceTable = (): React.ReactElement => {
    const {
        sourcesData: { sources },
        assumptions,
    } = useRootState();
    const { tableData, activeSourceIndex, setActiveSourceIndex } = useSourceTable();
    const handleClick = useCallback(
        (direction) => {
            if (direction === 'forward') setActiveSourceIndex(activeSourceIndex + 1);
            if (direction === 'backward') setActiveSourceIndex(activeSourceIndex - 1);
        },
        [activeSourceIndex, setActiveSourceIndex],
    );
    return (
        <div className="source-table-container">
            <div className="sources-navigation">
                {sources.map((el, index) => (
                    <Button
                        type="button"
                        key={el.id}
                        handleClick={() => setActiveSourceIndex(index)}
                        style={index === activeSourceIndex ? ActiveSourceButton : SourceButton}
                    >
                        {`${index + 1}. ${workloadTypeAliases[el.type]}`}
                    </Button>
                ))}
            </div>
            <div className="sourceTableWrapper">
                <Navigation
                    isVisible
                    handleClick={handleClick}
                    subHeader={`of ${sources.length}`}
                    disableBackward={activeSourceIndex === 0}
                    disableForward={activeSourceIndex === sources.length - 1}
                    header={`${activeSourceIndex + 1}. ${
                        WorkloadTypesEnum[sources[activeSourceIndex].type.toUpperCase()]
                    }`}
                />
                <Table
                    columns={columns(
                        capitalize(replace(`source-${activeSourceIndex + 1}`, '-', ' ')),
                        WorkloadTypesEnum[sources[activeSourceIndex].type.toUpperCase()],
                        assumptions.dedupe[sources[activeSourceIndex].type],
                    )}
                    data={tableData}
                    tableClassName="sourceTable"
                    rowKey="month"
                />
            </div>
        </div>
    );
};
export default SourceTable;
