import { createAction } from 'typesafe-actions';
import { SkuDefaultValuesType, SkuDiscountValuesType } from './sku.types';

export const updateSku = createAction('UPDATE_SKU', ({ plan, cloud, discounts }: SkuDefaultValuesType) => ({
    plan,
    cloud,
    discounts,
}))<SkuDefaultValuesType>();

export const resetSku = createAction('RESET')<null>();

export const updateDiscount = createAction(
    'UPDATE_DISCOUNT',
    ({ defaultValues, discounts }: SkuDiscountValuesType) => ({
        defaultValues,
        discounts,
    }),
)<SkuDiscountValuesType>();
