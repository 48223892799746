import React from 'react';
import { useRootState } from 'store/StateProvider';
import Input from 'components/forms/Input/Input';
import 'components/AssumptionsDrawer/assumptions-drawer.scss';

const DedupeTable = () => {
    const { assumptions } = useRootState();
    return (
        <table className="dedup-table">
            <thead>
                <tr>
                    <th>Data Types and Dedup Ratio</th>
                    <th> </th>
                    <th>Dedup(X)</th>
                </tr>
            </thead>
            <tbody>
                {assumptions.dedupe.data.map((row) => (
                    <tr key={row.workloadType}>
                        <td>{row.dataTypesAndDedupeRatio}</td>
                        <td>{row.workloadType}</td>
                        <td className="table-cell-editable">
                            <Input
                                type="number"
                                name={`dedupe.${row.key}`}
                                isEditable={assumptions.isEditing}
                                defaultValue={assumptions.dedupe[row.key]}
                            />
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default React.memo(DedupeTable);
