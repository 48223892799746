import { useEffect, useState } from 'react';
import { useRootState } from 'store/StateProvider';
import { TilesType } from 'types';
import { calculatePriceByCloud } from 'views/ResultsTabs/Summary/BackupTable/helpers';
import { skuPlanAliases, skuCloudAliases, getFormattedBytes } from 'utils/utils';

const dataType = 'monthlyData';
const useTilesData = (): TilesType[] => {
    const {
        calculatedData: { results },
        location: { isDell },
        sku,
        assumptions,
        sourcesData,
    } = useRootState();
    const [tilesData, setTilesData] = useState([]);

    useEffect(() => {
        if (results) {
            const tilesList = [
                {
                    label: 'No. of data sources',
                    value: sourcesData.sources.length,
                    isEditable: false,
                },
                {
                    label: 'Effective dedupe',
                    value:
                        Math.round(
                            (results.allSources[dataType][0]?.total / results.allSources[dataType][0]?.target) * 10,
                        ) / 10 || 0,
                    isEditable: false,
                },
                {
                    label: 'Total initial source data',
                    value: `${getFormattedBytes(
                        sourcesData.sources.reduce((prev, curr) => prev + parseFloat(curr.size.toString()), 0) *
                            1024 ** 4,
                    )}`,
                    isEditable: false,
                },
                {
                    label: 'Deployment Rate',
                    value: `${
                        Math.round(
                            (sourcesData.sources.reduce((cuurentValue, source) => source.size + cuurentValue, 0) /
                                Math.max(...sourcesData.sources.map((source) => source.deploymentPeriod))) *
                                10,
                        ) / 10
                    } TB/day`,
                    isEditable: false,
                },
            ];
            const druvaOnlyTiles = [
                {
                    label: 'Phoenix Storage SKU',
                    value: `${skuPlanAliases[sku.plan]}, ${skuCloudAliases[sku.cloud]}`,
                    isEditable: true,
                },
                {
                    label: 'List price per credit',
                    value: `$${calculatePriceByCloud(
                        assumptions.pricing[sku.plan],
                        sku.cloud,
                        assumptions.pricing.govCloudAddon,
                    )}`,
                    isEditable: false,
                },
            ];
            setTilesData(isDell ? tilesList : [...tilesList, ...druvaOnlyTiles]);
        }
    }, [isDell, sourcesData.sources, assumptions.pricing, results, sku.cloud, sku.plan]);
    return tilesData;
};

export default useTilesData;
