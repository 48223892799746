import { createMuiTheme } from '@material-ui/core';

const theme = createMuiTheme({
    overrides: {
        MuiInputBase: {
            root: {
                fontSize: '1.5rem',
            },
        },
        MuiButton: {
            contained: {
                boxShadow: 'none',
                padding: '1rem 1.6rem',
            },
        },
    },
});

export default theme;
