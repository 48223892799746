import MaterialTooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core';

const Tooltip = withStyles(() => ({
    tooltip: {
        backgroundColor: '#E9EBEB',
        color: 'inherit',
        fontSize: '12px',
        lineHeight: '17px',
        padding: '0.8rem 1.4rem',
        fontFamily: 'Lato',
        border: '1px solid orange',
    },
    arrow: {
        '&:before': {
            borderStyle: 'inherit',
            width: '10px !important',
            height: '10px !important',
            borderWidth: 'unset !important',
            transform: 'rotate(45deg)',
            color: 'transparent',
            backgroundColor: '#e9ebeb',
        },
    },
    tooltipPlacementLeft: {
        '&>span:before': {
            borderTop: '1px solid orange !important',
            borderRight: '1px solid orange !important',
            marginLeft: '-4.5px !important',
        },
    },

    tooltipPlacementBottom: {
        '&>span:before': {
            borderTop: '1px solid orange !important',
            borderLeft: '1px solid orange !important',
            marginBottom: '-4.5px !important',
        },
    },

    tooltipPlacementRight: {
        '&>span:before': {
            borderBottom: '1px solid orange !important',
            borderLeft: '1px solid orange !important',
            marginRight: '-4.5px !important',
        },
    },

    tooltipPlacementTop: {
        '&>span:before': {
            borderBottom: '1px solid orange !important',
            borderRight: '1px solid orange !important',
            marginTop: '-4.5px !important',
        },
    },
}))(MaterialTooltip);

export default Tooltip;
