import React from 'react';
import { Document, Page, View, Image, Text } from '@react-pdf/renderer';
import { TilesType, BackupData } from 'types';
import { SourceType } from 'store/slices/sources/sources.types';
import { SkuDefaultValuesType } from 'store/slices/sku/sku.types';
import { workloadTypeAliases } from 'utils/utils';
import styles, { stylesMapping, getStyledComponent } from './styles';
import { getDocumentData } from './utils';

const CellTextStyled = (props) => {
    const { children, style = {}, ...restProps } = props;
    return (
        <Text style={getStyledComponent('cellText', style)} {...restProps}>
            {children}
        </Text>
    );
};

const TextStyled = (props) => {
    const { children, style = {}, ...restProps } = props;
    return (
        <Text style={getStyledComponent('text', style)} {...restProps}>
            {children}
        </Text>
    );
};

const ViewStyled = (props) => {
    const { children, style = {}, ...restProps } = props;
    return (
        <View style={getStyledComponent('rowView', style)} {...restProps}>
            {children}
        </View>
    );
};

const HeaderStyled = (props) => {
    const { children, style = {}, ...restProps } = props;
    return (
        <Text style={getStyledComponent('headerText', style)} {...restProps}>
            {children}
        </Text>
    );
};

const mapToCell = (data, index, keyPrefix, style) => (
    <CellTextStyled style={Array.isArray(style) ? style[0] : style} key={`${keyPrefix}_${index}`}>
        {data}
    </CellTextStyled>
);

type Props = {
    summaryGraphSrc: string;
    sources: Array<SourceType>;
    backupData: BackupData;
    sku: SkuDefaultValuesType;
    backupACV: number[];
    effectivePrice: number[];
    tilesData: TilesType[];
    timeRange: number;
    priceInformation: boolean;
    isDell: boolean;
    customerDetails: { customername: String; organization: String };
    showCustomerInfo: Boolean;
};

const DocumentLayout = ({
    summaryGraphSrc,
    sources,
    backupData,
    sku,
    backupACV,
    effectivePrice,
    tilesData,
    timeRange,
    priceInformation,
    isDell = false,
    customerDetails,
    showCustomerInfo,
}: Props): JSX.Element => {
    const { dateText, formattedTiles, backupTableData, sourcesTableData, sourcesColumnWidths } = getDocumentData(
        sources,
        backupData,
        sku,
        backupACV,
        effectivePrice,
        tilesData,
        timeRange,
        priceInformation,
    );

    const title = isDell ? 'Dell PowerProtect Backup Service Sizing Tool' : 'Sizing tool';

    // Add some space above sources table (to align sources table in pdf correctly)
    // when timeRange is greater than 3 and it is druva (i.e. isDell is false) and priceinformation is true
    const extraSpace = timeRange > 3 && !isDell && priceInformation;

    const isCustomerDetailsRowAvailable = customerDetails.customername !== '' || customerDetails.organization !== '';
    return (
        <Document>
            <Page size="A4" style={styles.body}>
                <View style={styles.heading}>
                    <TextStyled>{title}</TextStyled>
                    <TextStyled style={stylesMapping.timestamp}>{dateText}</TextStyled>
                </View>
                <TextStyled style={stylesMapping.title}>Result</TextStyled>
                {showCustomerInfo && isCustomerDetailsRowAvailable && (
                    <View>
                        <TextStyled style={{ ...stylesMapping.headerText, marginTop: 24 }}>Customer Details</TextStyled>
                        <ViewStyled style={stylesMapping.customerDetails}>
                            {customerDetails.customername !== '' && (
                                <View>
                                    <ViewStyled>
                                        <TextStyled style={{ fontSize: 12 }}>Customer Name: </TextStyled>
                                        <TextStyled style={{ fontSize: 12 }}>{customerDetails.customername}</TextStyled>
                                    </ViewStyled>
                                </View>
                            )}
                            {customerDetails.organization !== '' && (
                                <View>
                                    <ViewStyled>
                                        <TextStyled style={{ fontSize: 12 }}>Organization/Site: </TextStyled>
                                        <TextStyled style={{ fontSize: 12 }}>{customerDetails.organization}</TextStyled>
                                    </ViewStyled>
                                </View>
                            )}
                        </ViewStyled>
                    </View>
                )}
                <HeaderStyled style={{ marginTop: 24 }}>Summary</HeaderStyled>
                <View style={isDell ? styles.summaryDell : styles.summary}>
                    {formattedTiles.map((tile, index) => (
                        <View
                            key={tile.label}
                            style={getStyledComponent('tileView', {
                                width: priceInformation ? '17.5vw' : '22vw',
                                marginRight: isDell && index !== formattedTiles.length - 1 ? 5 : 0,
                            })}
                        >
                            <TextStyled>{tile.label}</TextStyled>
                            <TextStyled>{tile.value}</TextStyled>
                        </View>
                    ))}
                </View>
                <HeaderStyled style={{ marginTop: 34 }}>Backup</HeaderStyled>
                <View style={timeRange > 3 ? styles.backupCol : styles.backup}>
                    <View
                        style={getStyledComponent('tableView', {
                            width: timeRange > 3 ? '100%' : '54%',
                            marginBottom: extraSpace ? 20 : 10,
                        })}
                    >
                        <ViewStyled>
                            <CellTextStyled style={{ width: '42%' }} />
                            {backupTableData.dataYears.data.map((year, index) =>
                                mapToCell(year, index, 'year', backupTableData.dataYears.style),
                            )}
                        </ViewStyled>
                        <ViewStyled style={{ backgroundColor: '#f7f7f8' }}>
                            <CellTextStyled style={{ width: '42%', backgroundColor: '#f9e4c6' }}>
                                Average Source+Changes (TB)
                            </CellTextStyled>
                            {backupTableData.dataSourceChanges.data.map((sourceChange, index) =>
                                mapToCell(
                                    sourceChange,
                                    index,
                                    'sourceChanges',
                                    backupTableData.dataSourceChanges.style,
                                ),
                            )}
                        </ViewStyled>
                        <ViewStyled>
                            <CellTextStyled style={{ width: '42%', backgroundColor: '#ffebcc' }}>
                                Average {isDell ? '' : 'Phoenix '}Storage (TB)
                            </CellTextStyled>
                            {backupTableData.dataTargetData.data.map((targetData, index) =>
                                mapToCell(targetData, index, 'targetData', backupTableData.dataTargetData.style),
                            )}
                        </ViewStyled>
                        <ViewStyled>
                            <CellTextStyled style={{ width: '42%', backgroundColor: '#f9e4c6' }}>
                                Credits Standard
                            </CellTextStyled>
                            {backupTableData.dataCreditsStandard.data.map((creditsStandard, index) =>
                                mapToCell(
                                    creditsStandard,
                                    index,
                                    'creditsNoLtrs',
                                    backupTableData.dataCreditsStandard.style,
                                ),
                            )}
                        </ViewStyled>
                        <ViewStyled style={{ backgroundColor: '#f7f7f8' }}>
                            <CellTextStyled style={{ width: '42%', backgroundColor: '#ffebcc' }}>
                                Credits LTR
                            </CellTextStyled>
                            {backupTableData.dataCreditsLtr.data.map((creditsLtr, index) =>
                                mapToCell(creditsLtr, index, 'creditsLtrs', backupTableData.dataCreditsLtr.style),
                            )}
                        </ViewStyled>
                        <ViewStyled>
                            <CellTextStyled style={{ width: '42%', backgroundColor: '#f9e4c6' }}>
                                Credits Archive
                            </CellTextStyled>
                            {backupTableData.dataCreditsDtc.data.map((creditsDtc, index) =>
                                mapToCell(creditsDtc, index, 'dtc', backupTableData.dataCreditsDtc.style),
                            )}
                        </ViewStyled>
                        <ViewStyled style={{ backgroundColor: '#ffebcc' }}>
                            <CellTextStyled style={{ width: '42%', backgroundColor: '#ffdba3' }}>
                                Credits Total
                            </CellTextStyled>
                            {backupTableData.dataCreditsTotal.data.map((creditsTotal, index) =>
                                mapToCell(creditsTotal, index, 'creditsTotal', backupTableData.dataCreditsTotal.style),
                            )}
                        </ViewStyled>
                        <View style={styles.dividerView} />
                        {backupTableData.dataDiscount && !isDell && (
                            <ViewStyled style={{ backgroundColor: '#e9ebec' }}>
                                <CellTextStyled style={{ width: '42%' }}>Discount (%)</CellTextStyled>
                                {backupTableData.dataDiscount.data.map((discount, index) =>
                                    mapToCell(`${discount}%`, index, 'discount', backupTableData.dataDiscount.style),
                                )}
                            </ViewStyled>
                        )}
                        {backupTableData.dataBackupACV && !isDell && (
                            <ViewStyled>
                                <CellTextStyled style={{ width: '42%' }}>Backup ACV</CellTextStyled>
                                {backupTableData.dataBackupACV.data.map((backup, index) =>
                                    mapToCell(`$${backup}`, index, 'backupACV', backupTableData.dataBackupACV.style),
                                )}
                            </ViewStyled>
                        )}
                        {backupTableData.dataEffectivePrice && !isDell && (
                            <ViewStyled style={{ backgroundColor: '#e9ebec' }}>
                                <CellTextStyled style={{ width: '42%' }}>Effective Price($/TB/Month)</CellTextStyled>
                                {backupTableData.dataEffectivePrice.data.map((price, index) =>
                                    mapToCell(
                                        `$${price}`,
                                        index,
                                        'effectivePrice',
                                        backupTableData.dataEffectivePrice.style,
                                    ),
                                )}
                            </ViewStyled>
                        )}
                    </View>
                    <Image
                        style={[timeRange > 3 ? styles.summaryGraphCol : styles.summaryGraph]}
                        src={summaryGraphSrc}
                    />
                </View>
                <HeaderStyled style={{ marginTop: extraSpace ? 80 : 34 }}>Sources</HeaderStyled>
                <View style={styles.sources}>
                    <View style={getStyledComponent('tableView', {})}>
                        <ViewStyled style={{ backgroundColor: '#FFCC80' }}>
                            {sourcesTableData.dataPrimaryHeaders.map((header, index) =>
                                mapToCell(header.header, index, 'primaryHeader', header.style),
                            )}
                        </ViewStyled>
                        <ViewStyled style={{ backgroundColor: '#FFEBCC' }}>
                            {sourcesTableData.dataSecondaryHeaders.map((header, index) =>
                                mapToCell(header.header, index, 'secondaryHeader', header.style),
                            )}
                        </ViewStyled>
                        {sourcesTableData.dataSources.map((source, index) => {
                            const { id, ...values } = source;
                            delete values.dtc;
                            const valuesToRender = {
                                ...values,
                                ...(values.type === workloadTypeAliases.archiveFsNas
                                    ? {
                                          daily: 'N/A',
                                          weekly: 'N/A',
                                          monthly: 'N/A',
                                      }
                                    : {}),
                            };

                            return (
                                <ViewStyled
                                    style={{
                                        backgroundColor: index % 2 === 0 ? '#fff' : '#f7f7f8',
                                    }}
                                    key={id}
                                >
                                    {Object.keys(valuesToRender).map((key, keyIndex) =>
                                        mapToCell(valuesToRender[key], keyIndex, id, {
                                            width: sourcesColumnWidths[keyIndex],
                                        }),
                                    )}
                                </ViewStyled>
                            );
                        })}
                    </View>
                </View>
                <View style={styles.footer} fixed>
                    <TextStyled style={{ fontSize: 7 }}>
                        This tool is confidential and proprietary to {isDell ? 'Dell EMC' : 'Druva'} and should not be
                        copied, distributed or reproduced in whole or in part, nor passed to any third party.
                    </TextStyled>
                    <TextStyled
                        style={{ position: 'absolute', right: 20 }}
                        fixed
                        render={({ pageNumber, totalPages }) => `${pageNumber}/${totalPages}`}
                    />
                </View>
            </Page>
        </Document>
    );
};
export default DocumentLayout;
