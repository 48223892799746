import React, { memo } from 'react';
import 'components/IconButton/icon-button.scss';
import { FormattedMessage } from 'react-intl';

interface Props {
    icon: string;
    text: string;
    onClick: (event) => void;
    // className?: string;
}
const IconButton = memo(({ icon, text, onClick }: Props) => (
    <button type="button" className="icon-button" onClick={(event) => onClick(event)}>
        <img src={icon} alt="assumptions icon" className="icon-button__icon" />
        <p className="icon-button__text">
            <FormattedMessage id={`${text}.text`} values={{ text }} defaultMessage={text} />
        </p>
    </button>
));

export default IconButton;
