import React, { memo, useEffect, useRef, useState } from 'react';
import { Input } from 'components/forms';
import { useRootState } from 'store/StateProvider';
import { Formik, Form } from 'formik';
import { saveCustomerDetails } from 'store/slices/actions';
import './Sources.scss';
import useSources from 'views/Sources/hooks/useSources';

const CustomerDetails = () => {
    const {
        sourcesData: {
            customerDetails: { customername, organization },
        },
    } = useRootState();
    const [formValues, setFormValues] = useState({ customername: '', organization: '' });

    const formikRef: any = useRef();

    const { dispatch } = useSources();

    let submitDetailsForm: any;

    // save the form values each time customername or organization name changes
    useEffect(() => {
        submitDetailsForm();
    }, [submitDetailsForm, formValues.customername, formValues.organization]);

    const handleSubmit = (values) => {
        dispatch(saveCustomerDetails(values));
    };

    // when reset will be triggered, this will help to reset the customername and organization field value.
    useEffect(() => {
        formikRef.current.setFieldValue('customername', customername);
        formikRef.current.setFieldValue('organization', organization);
    }, [customername, organization]);

    return (
        <Formik
            initialValues={{ customername, organization }}
            onSubmit={(values) => handleSubmit(values)}
            validateOnBlur={false}
            innerRef={formikRef}
        >
            {({ submitForm, values }) => {
                // update formValues and submitDetailsForm when there is any change in form.
                submitDetailsForm = submitForm;
                setFormValues(values);
                return (
                    <Form className="customer-details">
                        <div className="customer-details-hint">
                            <h3 className="section-heading">Add Customer Details</h3>
                            <div className="details-container">
                                <span>Customer Name</span>
                                <span>
                                    <Input name="customername" defaultValue="" isEditable type="text" />
                                </span>
                                <span>Organization/Site</span>
                                <span>
                                    <Input name="organization" defaultValue="" isEditable type="text" />
                                </span>
                            </div>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default memo<any>((props) => <CustomerDetails {...props} />);
