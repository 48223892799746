import { withStyles, makeStyles, Theme, StepConnector, createStyles, StepLabel } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '600px',
            backgroundColor: 'transparent',
        },
        label: {
            fontSize: '1.5rem',
        },
        backButton: {
            marginRight: theme.spacing(1),
        },
        instructions: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
    }),
);
export const LabelStyled = withStyles({
    label: {
        fontSize: '1.5rem',
        color: 'rgba(35, 47, 62, 0.5);',
        fontFamily: 'Lato',
    },
})(StepLabel);

export const useStepIconStyles = makeStyles({
    root: {
        display: 'flex',
        height: 29,
        width: 29,
        backgroundColor: '#C4C4C4',
        borderRadius: '50%',
        color: 'rgba(35, 47, 62, 0.5)',
    },
    active: {
        height: 29,
        width: 29,
        borderRadius: '50%',
        backgroundColor: '#FF9B04',
        zIndex: 1,
        fontSize: 18,
        color: '#ffffff',
    },
    circle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 29,
        width: 29,
        borderRadius: '50%',
        zIndex: 1,
        fontSize: 18,
    },
    completed: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 29,
        width: 29,
        borderRadius: '50%',
        backgroundColor: '#05C42F',
        zIndex: 1,
        fontSize: 18,
        color: '#ffffff',
    },
});

export const Connector = withStyles({
    alternativeLabel: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    line: {
        borderColor: '#C4C4C4',
        borderTopWidth: 2,
        borderRadius: 0,
    },
})(StepConnector);
