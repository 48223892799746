import React, { memo } from 'react';
import './LegalText.scss';

const LegalText = memo(() => (
    <p className="legal-text">
        This tool is confidential and proprietary to Druva Inc. and should not be copied, distributed or reproduced in
        whole or in part, nor passed to any third party.
    </p>
));

export default LegalText;
