import React from 'react';
import { FormControl } from '@material-ui/core';
import { InputBaseStyled } from 'components/forms/Input/styles';
import { useInput } from 'components/forms/hooks/useInput';

type InputProps = {
    name: string;
    type?: string;
    isEditable?: boolean;
    clearError?: boolean;
    defaultValue?: number | string;
    onValueChange?: Function;
    disabled?: boolean;
    min?: number;
    max?: number;
    onInputBlur?: Function;
};

const Input = ({
    name,
    type,
    isEditable,
    clearError,
    defaultValue,
    onValueChange,
    disabled,
    min,
    max,
    onInputBlur,
}: InputProps): JSX.Element => {
    const isTypeNumber = type === 'number';
    const { meta, field, onChange, onBlur } = useInput({ name, defaultValue, clearError, isTypeNumber });
    const inputType = isTypeNumber ? 'text' : type;

    const handleChange = (e) => {
        if (min !== undefined && e.target.value < min) {
            e.target.value = min;
        }
        if (max !== undefined && e.target.value > max) {
            e.target.value = Math.floor(e.target.value / 10);
        }
        if (typeof onValueChange === 'function') {
            onValueChange(e);
        }
        onChange(e);
    };

    const handleBlur = (e) => {
        if (typeof onInputBlur === 'function') {
            onInputBlur(e);
        }
        onBlur(e);
    };

    return isEditable ? (
        <FormControl error={Boolean(meta.error && meta.touched)} variant="outlined">
            <InputBaseStyled
                {...field}
                type={inputType}
                onBlur={handleBlur}
                onChange={handleChange}
                disableUnderline
                disabled={disabled}
            />
        </FormControl>
    ) : (
        <span>{field.value}</span>
    );
};

Input.defaultProps = {
    type: '',
    isEditable: false,
    clearError: false,
    defaultValue: null,
    onValueChange: undefined,
    disabled: false,
    min: undefined,
    max: undefined,
    onInputBlur: undefined,
};

export default Input;
