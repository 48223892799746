import React, { memo } from 'react';
import SectionLabel from 'components/SectionLabel/SectionLabel';
import Select from 'components/forms/Select/Select';
import { SKU_OPTIONS, CLOUD_OPTIONS } from 'config/setup/constants';
import { SkuOptionsEnum } from 'store/slices/sku/sku.types';
import 'views/Sku/sku.scss';

type Props = {
    disableBusiness?: boolean;
    values?: { plan: string; cloud: string; discounts: Array<number> };
};

const Sku: React.FC<Props> = ({ disableBusiness, values }) => {
    const skuOptions = disableBusiness
        ? SKU_OPTIONS.filter((option) => option !== SkuOptionsEnum.BUSINESS)
        : SKU_OPTIONS;

    if (disableBusiness && values?.plan === 'business') {
        values.plan = '';
    }

    return (
        <div className="form__form-row row-3">
            <SectionLabel>SKU</SectionLabel>
            <Select name="plan" options={skuOptions} />
            <Select name="cloud" options={CLOUD_OPTIONS} />
        </div>
    );
};

Sku.defaultProps = {
    disableBusiness: false,
    values: { plan: '', cloud: '', discounts: [] },
};

export default memo(Sku);
