import { useEffect } from 'react';
import omit from 'lodash/omit';
import isEqual from 'lodash/isEqual';
import { useRootState, useRootDispatch } from 'store/StateProvider';
import { AllSourcesDataResponse, ApiInputs, CalculatedDataResponse } from 'types';
import { PayloadAction } from 'typesafe-actions';
import { useApi } from 'hooks/useApi';

function prepareAssumptions<T extends object>(object: T): Omit<T, 'data'> {
    return omit(object, 'data');
}

export function useCalculatedData<T>(
    url: 'calculate-all' | 'calculate-summary-table',
    action: (
        args: T,
    ) =>
        | PayloadAction<'UPDATE_SUMMARY', CalculatedDataResponse>
        | PayloadAction<'UPDATE_SOURCES_RESULTS', AllSourcesDataResponse>,
): { loading: boolean; response: T | null; error: Error } {
    const dispatch = useRootDispatch();
    const {
        sourcesData: { sources, tcoYear },
        sku: { plan, cloud, discounts },
        assumptions: { defaultAssumptions, dedupe, ltr, dtc, pricing, changeRates },
    } = useRootState();

    const currentAssumptions = {
        dedupe: prepareAssumptions<typeof dedupe>(dedupe),
        ltr: prepareAssumptions<typeof ltr>(ltr),
        dtc: prepareAssumptions<typeof dtc>(dtc),
        pricing: prepareAssumptions<typeof pricing>(pricing),
        changeRates: prepareAssumptions<typeof changeRates>(changeRates),
    };

    const { response, loading, error } = useApi<ApiInputs, T>({
        method: 'POST',
        payload: {
            sources: sources.map((source) => ({
                ...source,
                annualGrowth: source.annualGrowth / 100,
                dailyChange: source.dailyChange / 100,
            })),
            plan,
            cloud,
            discounts: discounts.map((discount) => discount / 100),
            duration: tcoYear,
            // send current assumptions if they are different than default
            ...(!isEqual(defaultAssumptions, currentAssumptions) && { assumptions: { ...currentAssumptions } }),
        },
        url,
    });

    useEffect(() => {
        if (response) dispatch(action(response));
    }, [response, dispatch, action]);

    return { response, loading, error };
}
