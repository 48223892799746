import React, { memo, ReactElement } from 'react';
import 'components/forms/Input/InputTitle/inputTitle.scss';

type Props = {
    text: string;
    tooltip?: ReactElement<JSX.Element>;
};

const InputTitleComponent = ({ text, tooltip }) => (
    <div className="input-title">
        <h3 className="input-title__text">{text}</h3>
        {tooltip}
    </div>
);

InputTitleComponent.defaultProps = {
    tooltip: null,
};

export default memo<Props>((props) => <InputTitleComponent {...props} />);
