import { SourceType } from '../store/slices/sources/sources.types';

export const workloadTypeAliases = {
    vMware: 'VMware',
    nas: 'NAS',
    windows: 'Windows',
    linux: 'Linux',
    msSql: 'MS-SQL',
    oracle: 'Oracle',
    sapHana: 'SAP HANA',
    azureVm: 'Azure VM',
    hyperV: 'Hyper-V',
    archiveFsNas: 'Archive (FS/NAS)',
    nutanixAhv: 'Nutanix AHV',
    ec2: 'EC2',
};

export const skuPlanAliases = {
    business: 'Business',
    enterprise: 'Enterprise',
    elite: 'Elite',
};

export const skuCloudAliases = {
    govCloud: 'Gov Cloud',
    publicCloud: 'Public Cloud',
};

export const formatSources = (sources: Array<SourceType>): Array<SourceType> =>
    sources.map((s) => ({ ...s, ltr: s.ltr ? 'yes' : 'no' })).map((s) => ({ ...s, dtc: s.dtc ? 'yes' : 'no' }));

/**
 * Converts the bytes input into appropriate KB/MB/GB string format
 * getFormattedBytes(6357407409) will return '5.9 GB'
 * getFormattedBytes(657905499) will return '627.4 MB'
 */
export const getFormattedBytes = (bytes: number, decimals = 2): string => {
    if (bytes === 0) {
        return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;

    // Byte, Kilo Byte, Mega Byte, Giga Byte, Tera Byte, Peta Byte, Exa Byte, Zetta Byte, Yotta Byte
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

const dataSizeMappingWithDeploymentPeriod = {
    50: 15,
    100: 30,
    200: 60,
    300: 90,
    400: 120,
    500: 150,
};

export const getDeploymentPeriodBasedOnDataSize = (dataSize: string): number => {
    for (const size in dataSizeMappingWithDeploymentPeriod) {
        if (+dataSize <= +size) {
            return dataSizeMappingWithDeploymentPeriod[size];
        }
    }
    return 180;
};

export const getDailyGrowth = (annualGrowth) => {
    const annualGrowthRate = annualGrowth / 100;
    const dailyGrowth = (1 + annualGrowthRate) ** (1 / 365) - 1;
    return +(dailyGrowth * 100).toFixed(7);
};

export const getAnnualGrowth = (dailyGrowth) => {
    const dailyGrowthRate = dailyGrowth / 100;
    const annualGrowth = (1 + dailyGrowthRate) ** 365 - 1;
    return +(annualGrowth * 100).toFixed(2);
};
