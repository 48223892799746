import {
    authInitialState,
    assumptionsInitialState,
    calculatedDataInitialState,
    skuInitialState,
    sourcesInitialState,
    stepsInitialState,
    locationInitialStore,
} from './slices';
import { AppState } from './store.types';

export const storeInitialState: AppState = {
    assumptions: assumptionsInitialState,
    auth: authInitialState,
    calculatedData: calculatedDataInitialState,
    sku: skuInitialState,
    sourcesData: sourcesInitialState,
    steps: stepsInitialState,
    location: locationInitialStore,
};
