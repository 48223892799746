import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Close } from '@material-ui/icons';
import { confirmButton, confirmCancelButton } from 'components/Button/styles';
import './dialog.scss';

const ArchiveSelectedConfirmationDialog = ({ isOpen, onClose, onContinue, showDailyChangeMessage }): JSX.Element => (
    <Dialog
        open={isOpen}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        classes={{
            container: 'dialog',
        }}
    >
        <div className="confirm-dialog">
            <DialogTitle id="dialog-title">Archive Selected</DialogTitle>
            <Close className="dialog-close-icon" color="disabled" onClick={onClose} />

            <div className="confirm-dialog-content">
                The default dedupe ratio for Archive storage is set to 1, indicating that no data deduplication is
                applied. This is because unstructured data, such as documents, images, and multimedia files, typically
                contain less redundancy. Please change the dedupe ratio appropriately based on customers' data.
                {showDailyChangeMessage && (
                    <>
                        <br />
                        <br />
                        <span className="label-red">
                            Regular data updates or modifications in the Archive storage tier may result in higher
                            credit consumption.
                        </span>{' '}
                        Archive is intended for static data that remains unchanged. New data can be added, but ongoing
                        modifications may incur additional charges. Please consider your data usage and choose the
                        appropriate storage option accordingly.
                    </>
                )}
            </div>
            <div className="confirm-dialog-buttons">
                <Button style={confirmCancelButton} onClick={onClose}>
                    Cancel
                </Button>
                <Button onClick={onContinue} style={confirmButton}>
                    Continue
                </Button>
            </div>
        </div>
    </Dialog>
);

export default ArchiveSelectedConfirmationDialog;
