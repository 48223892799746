import { useCallback } from 'react';
import { useField } from 'formik';

type useInputsProps = {
    name: string;
    defaultValue?: number | string;
    clearError?: boolean;
    isTypeNumber?: boolean;
};

export const parseStringToFloat = (input: string): string => {
    let output = input;
    // strip leading zeros if needed
    if (output?.length > 1 && output[1] !== '.') output = output.replace(/^0+/g, '');
    // strip any non-number symbols
    output = output.replace(/[^0-9.]/g, '');
    // strip unnecessary dots and commas
    const splitString = output.split(/[.,]/);
    const integerPart = splitString.shift();
    const fractionalPart = splitString.length ? `.${splitString.join('')}` : '';
    return `${integerPart}${fractionalPart}`;
};

export const useInput = ({
    name,
    defaultValue,
    clearError,
    isTypeNumber,
}: useInputsProps): { meta; field; onChange; onBlur } => {
    const [field, meta, helpers] = useField(name);
    const onBlur = useCallback(
        (e) => {
            if (e.target.value === '' || e.target.value === 'e') {
                e.target.value = defaultValue;
            }

            if (isTypeNumber) {
                e.target.value = parseFloat(e.target.value) || 0;
            }

            field.onBlur(e);
            field.onChange(e);
        },
        [field, defaultValue, isTypeNumber],
    );
    const onChange = useCallback(
        (e) => {
            if (clearError) {
                helpers.setError(null);
            }
            if (isTypeNumber) {
                e.target.value = parseStringToFloat(e.target.value);
            }

            field.onChange(e);
        },
        [field, clearError, helpers, isTypeNumber],
    );
    return { meta, field, onChange, onBlur };
};
