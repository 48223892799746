import React from 'react';
import { Tab, Tabs as TabsComponent, TabList, TabPanel } from 'react-tabs';
import _ from 'lodash';

import 'react-tabs/style/react-tabs.css';
import 'views/ResultsTabs/tabs.scss';

import { useCalculatedData } from 'hooks/useCalculatedData';
import { CalculatedDataResponse } from 'types';
import { updateCalculatedData } from 'store/slices/calculatedData/calculatedDataActions';
import { updateDiscount, updateTCOYear, updateSourcesResults } from 'store/slices/actions';
import { useRootState, useRootDispatch } from 'store/StateProvider';
import { CircularProgress } from '@material-ui/core';
import ProgresGraphs from 'views/ProgressGraphs/ProgressGraphs';

import SelectTCOYear from 'components/SelectTCOYear/SelectTCOYear';
import PDFButton from 'components/PDFButton/PDFButton';
import { usePdfDataContext } from 'config/context/pdfDataContext';
import SummaryTable from './SummaryTable/SummaryTable';
import Summary from './Summary/Summary';
import SummaryGraph from './Summary/BackupTable/SummaryGraph/SummaryGraph';

import SourceTable from './SourceTable/SourceTable';

const tabsList = [
    { name: 'Summary', Component: Summary },
    { name: 'Summary Table', Component: SummaryTable },
    { name: 'Sources', Component: SourceTable },
    { name: 'Storage Growth', Component: ProgresGraphs },
];

const Tabs = (): React.ReactElement => {
    const { loading: loadingSources } = useCalculatedData<CalculatedDataResponse>(
        'calculate-all',
        updateSourcesResults,
    );
    const {
        sku: { discounts, defaultValues },
        sourcesData: { tcoYear },
    } = useRootState();
    const dispatch = useRootDispatch();
    const { loading } = useCalculatedData<CalculatedDataResponse>('calculate-summary-table', updateCalculatedData);
    const { tabIndex, changeTabIndex } = usePdfDataContext();

    const handleChange = (value: number) => {
        // Update discount as per selected value
        dispatch(
            updateDiscount({
                discounts: _.range(value).map((index) => discounts[index] ?? 0),
                defaultValues: {
                    ...defaultValues,
                    discounts: _.range(value).map(() => 0),
                },
            }),
        );
        // Update selected tcoYear
        dispatch(updateTCOYear(value));
    };
    return (
        <TabsComponent className="tabs" selectedIndex={tabIndex} onSelect={changeTabIndex}>
            <div
                style={{ visibility: 'visible', position: 'absolute', top: 0, left: '-9999px' }}
                className="summary-graph-for-pdf"
            >
                <SummaryGraph />
            </div>
            <SelectTCOYear style={{ paddingLeft: '8px' }} tcoYear={tcoYear} handleValueChange={handleChange} />
            <PDFButton className="tabs__button-export" />
            <TabList className="tabs__tabs-container">
                {tabsList.map((tab) => (
                    <Tab key={tab.name} className="tabs__tab" selectedClassName="tabs__tab-active">
                        {tab.name}
                    </Tab>
                ))}
            </TabList>

            {tabsList.map(({ name, Component }) => (
                <TabPanel key={name}>
                    {loading || loadingSources ? (
                        <div className="tabs__loader-wrapper">
                            <CircularProgress />
                        </div>
                    ) : (
                        <Component />
                    )}
                </TabPanel>
            ))}
        </TabsComponent>
    );
};

export default Tabs;
