import { getDailyGrowth } from 'utils/utils';
import { WorkloadTypesEnum, WorkloadEnum } from '../../../types';
import { SourceType, SourcesState } from './sources.types';

const sourceDefaultValues: SourceType = {
    type: 'select',
    dedupe: 0,
    size: 0,
    annualGrowth: 10,
    dailyGrowth: getDailyGrowth(10),
    dailyChange: 1,
    deploymentPeriod: 15,
    retention: {
        daily: 14,
        weekly: 4,
        monthly: 3,
        yearly: 3,
    },
    ltr: false,
    dtc: false,
};

const ec2SourceDefaultValues: SourceType = {
    ...sourceDefaultValues,
    retention: {
        daily: 7,
        weekly: 4,
        monthly: 12,
        yearly: 7,
    },
    ltr: true,
};

const archiveFsNasSourceDefaultValues: SourceType = {
    ...sourceDefaultValues,
    retention: {
        daily: 365,
        weekly: 52,
        monthly: 12,
        yearly: 1,
    },
    dailyChange: 0,
    ltr: false,
    dtc: true,
};

export const sourcesInitialState: SourcesState = {
    sources: [
        {
            id: 'source-1',
            ...sourceDefaultValues,
        },
    ],
    count: 1,
    workloadsOptions: [
        WorkloadTypesEnum.SELECT,
        WorkloadTypesEnum.EC2,
        WorkloadTypesEnum.AZUREVM,
        WorkloadTypesEnum.VMWARE,
        WorkloadTypesEnum.HYPERV,
        WorkloadTypesEnum.NUTANIXAHV,
        WorkloadTypesEnum.NAS,
        WorkloadTypesEnum.WINDOWS,
        WorkloadTypesEnum.LINUX,
        WorkloadTypesEnum.ARCHIVEFSNAS,
        WorkloadTypesEnum.MSSQL,
        WorkloadTypesEnum.ORACLE,
        WorkloadTypesEnum.SAPHANA,
    ],
    defaultValues: {
        [WorkloadEnum.SELECT]: sourceDefaultValues,
        [WorkloadEnum.EC2]: ec2SourceDefaultValues,
        [WorkloadEnum.AZUREVM]: sourceDefaultValues,
        [WorkloadEnum.VMWARE]: { ...sourceDefaultValues, ltr: true },
        [WorkloadEnum.HYPERV]: { ...sourceDefaultValues, ltr: true },
        [WorkloadEnum.NUTANIXAHV]: { ...sourceDefaultValues, ltr: true },
        [WorkloadEnum.NAS]: { ...sourceDefaultValues, ltr: true },
        [WorkloadEnum.WINDOWS]: { ...sourceDefaultValues, ltr: true },
        [WorkloadEnum.LINUX]: { ...sourceDefaultValues, ltr: true },
        [WorkloadEnum.ARCHIVEFSNAS]: archiveFsNasSourceDefaultValues,
        [WorkloadEnum.MSSQL]: { ...sourceDefaultValues, ltr: true },
        [WorkloadEnum.ORACLE]: { ...sourceDefaultValues, ltr: true },
        [WorkloadEnum.SAPHANA]: sourceDefaultValues,
    },
    data: null,
    tcoYear: 3,
    customerDetails: {
        customername: '',
        organization: '',
    },
};
