import React from 'react';
import Tile from 'components/Tile/Tile';
import useTilesData from './useTilesData';
import 'components/Tiles/tiles.scss';

const Tiles = (): JSX.Element => {
    const data = useTilesData();
    return (
        <div className="tiles">
            {data.map((tile) => (
                <Tile key={tile.label} label={tile.label} value={tile.value} isEditable={tile.isEditable} />
            ))}
        </div>
    );
};

export default Tiles;
