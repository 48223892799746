import React, { useCallback, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { resetPassword, confirmResetPassword } from 'aws-amplify/auth';
import { Formik, Form } from 'formik';
import MaterialButton from 'components/Button/Button';
import { resetPasswordButton } from 'components/Button/styles';
import Input from 'components/forms/Input/Input';
import { toast } from 'react-toastify';
import './forgot-password.scss';
import { CircularProgress } from '@material-ui/core';
import TooltipIcon from 'components/Tooltip/TooltipIcon';

const ForgotPassword = (): React.ReactElement => {
    const history = useHistory();
    const [step, setStep] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [stateUsername, setUsername] = useState('');
    const location = useLocation();
    const { locationStep } = location.state || ({ locationStep: 0 } as any);
    const { locationUsername } = location.state || ({ locationUsername: '' } as any);

    const handleResetPassword = useCallback((values) => {
        setIsLoading(true);
        resetPassword({ username: values.username })
            .then((data) => {
                if (data) {
                    setIsLoading(false);
                    setStep(1);
                    setUsername(values.username);
                }
            })
            .catch((err) => {
                setIsLoading(false);
                toast.error(err.message, {
                    position: 'bottom-right',
                });
            });
    }, []);

    const handleSendCodeAndNewPassword = useCallback(
        (values) => {
            setIsLoading(true);
            let username: string;
            if (locationUsername) {
                username = locationUsername;
            } else {
                username = stateUsername;
            }
            confirmResetPassword({ username, confirmationCode: values.code, newPassword: values.newPassword })
                .then(() => {
                    setIsLoading(false);
                    toast.success('You have successfully changed your password.', {
                        position: 'bottom-right',
                    });
                    history.push('/login');
                })
                .catch((err) => {
                    setIsLoading(false);
                    toast.error(err.message, {
                        position: 'bottom-right',
                    });
                });
        },
        [locationUsername, stateUsername, history],
    );
    return (
        <div className="login">
            <div className="login__container">
                <p className="login__signin-text">Reset Password</p>
                {isLoading ? (
                    <CircularProgress />
                ) : (
                    <Formik
                        initialValues={{
                            username: '',
                            code: '',
                            newPassword: '',
                        }}
                        onSubmit={() => {}}
                    >
                        {({ values }) => (
                            <Form className="form-password-reset" autoComplete="off">
                                {step === 0 && locationStep === 0 && (
                                    <>
                                        <div className="form-password-reset__group">
                                            <label htmlFor="username">Email</label>
                                            <Input type="text" name="username" isEditable defaultValue="" />
                                        </div>
                                        <div className="form-password-reset__actions">
                                            <MaterialButton
                                                type="submit"
                                                style={resetPasswordButton}
                                                handleClick={() => {
                                                    handleResetPassword(values);
                                                }}
                                            >
                                                Send Verification Code
                                            </MaterialButton>
                                        </div>
                                    </>
                                )}
                                {(step === 1 || locationStep === 1) && (
                                    <>
                                        <div className="form-password-reset__group">
                                            <p>
                                                On your email address we sent you a verification code. Please enter it
                                                below.{' '}
                                            </p>
                                        </div>
                                        <div className="form-password-reset__group">
                                            <label htmlFor="code">Verification Code</label>
                                            <Input type="text" name="code" isEditable defaultValue="" />
                                        </div>
                                        <div className="form-password-reset__group">
                                            <div className="label-with-tooltip">
                                                <label htmlFor="newPassword">New password</label>
                                                <TooltipIcon
                                                    title="Min. length 12, need to include: uppercase, lowercase, number and special character."
                                                    arrow
                                                    placement="right"
                                                />
                                            </div>
                                            <Input type="password" name="newPassword" isEditable defaultValue="" />
                                        </div>
                                        <div className="form-password-reset__actions">
                                            <MaterialButton
                                                type="submit"
                                                style={resetPasswordButton}
                                                handleClick={() => {
                                                    handleSendCodeAndNewPassword(values);
                                                }}
                                            >
                                                Reset Password
                                            </MaterialButton>
                                        </div>
                                    </>
                                )}
                            </Form>
                        )}
                    </Formik>
                )}
            </div>
        </div>
    );
};

export default ForgotPassword;
