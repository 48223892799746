import React, { memo, useState } from 'react';
import { Formik, Form, FieldArray } from 'formik';
import {
    nextButton,
    nextButtonDisabled,
    addButton,
    resetButton,
    resetModalButton,
    saveButton,
    cancelButton,
    saveButtonDisabled,
} from 'components/Button/styles';
import _ from 'lodash';
import { sourcesValidationSchema } from 'views/Sources/SourcesForm/SourcesForm.validation';
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button/Button';
import useSources from 'views/Sources/hooks/useSources';
import {
    resetSources,
    resetSku,
    resetSteps,
    resetCount,
    resetTCOYear,
    updateDiscount,
    resetCustomerDetails,
} from 'store/slices/actions';
import 'views/Sources/SourcesForm/SourcesForm.scss';
import AddSource from 'assets/add-icon.png';
import { useDialogContext } from 'config/context/dialogContext';
import { formatSources } from 'utils/utils';
import ArchiveSelectedConfirmationDialog from 'components/Dialog/ArchiveSelectedConfirmationDialog';
import DailyChangeConfirmationDialog from 'components/Dialog/DailyChangeConfirmationDialog';
import SourceFields from './SourceFields/SourceFields';
import './SourcesForm.scss';
import { WorkloadEnum } from '../../../types';

type Props = {
    isEditDialog: boolean;
};

const SourcesForm = memo(({ isEditDialog }: Props) => {
    const {
        dispatch,
        state: {
            sourcesData: { sources, tcoYear, defaultValues: defaultValuesSource },
            sku: { discounts, defaultValues },
        },
        handleAddSource,
        handleSubmit,
    } = useSources();
    const [isOpen, setIsOpen] = useState(false);
    const [showDailyChangeMessage, setShowDailyChangeMessage] = useState(false);
    const [isOpenDailyChangeConfirmation, setIsOpenDailyChangeConfirmation] = useState(false);
    const [formSources, setFormSources] = useState([]);

    const { close } = useDialogContext();

    const handleFormSubmit = (vSources) => {
        // Update discount as per selectedTcoYear
        dispatch(
            updateDiscount({
                discounts: _.range(+tcoYear).map((index) => discounts[index] ?? 0),
                defaultValues: {
                    ...defaultValues,
                    discounts: _.range(+tcoYear).map(() => 0),
                },
            }),
        );

        handleSubmit(vSources, isEditDialog);
    };

    const handleNextSave = (values) => {
        setFormSources(values.sources);
        if (values.sources.some((obj) => obj.type === WorkloadEnum.ARCHIVEFSNAS)) {
            setShowDailyChangeMessage(
                values.sources.some((obj) => obj.type === WorkloadEnum.ARCHIVEFSNAS && obj.dailyChange > 0),
            );
            setIsOpen(true);
        } else {
            handleFormSubmit(values.sources);
        }
    };

    const handleConfirmationClose = () => {
        setIsOpen(false);
    };

    const handleDailyChangeConfirmationClose = () => {
        setIsOpenDailyChangeConfirmation(false);
    };

    const handleContinue = () => {
        setIsOpen(false);
        handleFormSubmit(formSources);
    };

    return (
        <div className="sources">
            <Formik
                initialValues={{ sources: formatSources(sources), tcoYear }}
                onSubmit={handleNextSave}
                validationSchema={sourcesValidationSchema}
                validateOnBlur={false}
            >
                {({ values, resetForm, dirty, isValid, touched, setFieldValue }) => (
                    <>
                        <Form className="source">
                            {!isEditDialog && (
                                <Button
                                    type="button"
                                    handleClick={() => {
                                        resetForm();
                                        dispatch(resetSku());
                                        dispatch(resetSteps());
                                        dispatch(resetSources());
                                        dispatch(resetCount());
                                        dispatch(resetTCOYear());
                                        dispatch(resetCustomerDetails());
                                        setFieldValue(
                                            'sources',
                                            formatSources([defaultValuesSource[WorkloadEnum.SELECT]]),
                                        );
                                    }}
                                    style={resetButton}
                                >
                                    Reset
                                </Button>
                            )}
                            <FieldArray
                                name="sources"
                                validateOnChange
                                render={(sourcesArray) => (
                                    <>
                                        <Button
                                            handleClick={() => handleAddSource(sourcesArray)}
                                            style={addButton(isEditDialog)}
                                        >
                                            <FormattedMessage id="add.text" defaultMessage="Add" />
                                            <img src={AddSource} alt="add source" className="source__icon" />
                                        </Button>
                                        <div className="source__form__container">
                                            {values.sources.map((source, index) => (
                                                <div key={source.id} className="source__form">
                                                    <SourceFields
                                                        index={index}
                                                        fieldsArray={sourcesArray}
                                                        touched={touched}
                                                        showDailyChangeConfirmation={() =>
                                                            setIsOpenDailyChangeConfirmation(true)
                                                        }
                                                    />
                                                </div>
                                            ))}
                                        </div>

                                        {isEditDialog ? (
                                            <>
                                                <Button
                                                    handleClick={() => {
                                                        for (let i = 0; i < values.sources.length; i += 1) {
                                                            sourcesArray.pop();
                                                        }
                                                        sourcesArray.remove(0);
                                                    }}
                                                    style={resetModalButton}
                                                >
                                                    Reset
                                                </Button>
                                                <Button handleClick={close} style={cancelButton}>
                                                    Cancel
                                                </Button>
                                                <Button
                                                    type="submit"
                                                    style={
                                                        !values.sources.length || !dirty || !isValid
                                                            ? saveButtonDisabled
                                                            : saveButton
                                                    }
                                                    isDisabled={!values.sources.length || !dirty || !isValid}
                                                >
                                                    Save
                                                </Button>
                                            </>
                                        ) : (
                                            <Button
                                                type="submit"
                                                style={
                                                    !isValid ||
                                                    !values.sources.length ||
                                                    (!dirty && values.sources[0].type === 'select')
                                                        ? nextButtonDisabled
                                                        : nextButton
                                                }
                                                isDisabled={
                                                    !isValid ||
                                                    !values.sources.length ||
                                                    (!dirty && values.sources[0].type === 'select')
                                                }
                                            >
                                                Next
                                            </Button>
                                        )}
                                    </>
                                )}
                            />
                        </Form>
                        {!values.sources.length && <div className="source__no-sources">No sources, add new one.</div>}
                        <ArchiveSelectedConfirmationDialog
                            isOpen={isOpen}
                            onClose={handleConfirmationClose}
                            onContinue={handleContinue}
                            showDailyChangeMessage={showDailyChangeMessage}
                        />
                        <DailyChangeConfirmationDialog
                            isOpen={isOpenDailyChangeConfirmation}
                            onClose={handleDailyChangeConfirmationClose}
                            onContinue={handleDailyChangeConfirmationClose}
                        />
                    </>
                )}
            </Formik>
        </div>
    );
});

export default SourcesForm;
