import React from 'react';
import { Link } from 'react-router-dom';

const SignUpSuccess = (): JSX.Element => (
    <div>
        <div>You have been successfully signed up! Now you need to wait till administrators approve you account.</div>
        <Link to="/login">Go back to login screen</Link>
    </div>
);

export default SignUpSuccess;
