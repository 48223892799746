import { withStyles, Paper } from '@material-ui/core';

export const PaperStyled = withStyles({
    root: {
        borderRadius: 3,
        boxShadow: '0rem 0.4rem 5rem rgba(239, 243, 244, 0.5)',
        padding: '1.8rem 2.8rem',
        height: '10rem',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
})(Paper);
