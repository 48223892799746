export enum StepsEnum {
    SOURCES = 'Sources',
    SKU_DISCOUNTS = 'SKU & Discount',
    RESULTS = 'Results',
}

export type StepsState = {
    step: number;
    steps: Array<StepsEnum>;
};
