import { useCallback } from 'react';
import { useRootState, useRootDispatch } from 'store/StateProvider';
import { updateSources, addCount } from 'store/slices/sources/sourcesActions';
import { nextStep } from 'store/slices/steps/stepsActions';
import uuid from 'uuid';
import { useDialogContext } from 'config/context/dialogContext';
import { formatSources } from 'utils/utils';
import { RootActionTypes, AppState } from 'store/store.types';
import { WorkloadEnum } from '../../../types';

type RootDispatchType = (action: RootActionTypes) => void;

type useSourcesType = {
    dispatch: RootDispatchType;
    state: AppState;
    handleAddSource: (helper: any) => void;
    handleSubmit: (values: any, isEditDialog: boolean) => void;
};

const useSources = (): useSourcesType => {
    const dispatch = useRootDispatch();
    const state = useRootState();
    const { close } = useDialogContext();

    const handleAddSource = useCallback(
        (helper) => {
            const formattedSources = formatSources([state.sourcesData.defaultValues[WorkloadEnum.SELECT]]);
            dispatch(addCount());
            helper.push({
                id: uuid(),
                ...formattedSources[0],
            });
        },
        [state.sourcesData.defaultValues, dispatch],
    );
    const handleSubmit = useCallback(
        (values, isEditDialog) => {
            const payload = values.map((value) => ({
                ...value,
                size: Number(value.size),
                annualGrowth: Number(value.annualGrowth),
                dailyGrowth: Number(value.dailyGrowth),
                dailyChange: Number(value.dailyChange),
                deploymentPeriod: Number(value.deploymentPeriod),
                retention: {
                    daily: Number(value.retention.daily),
                    weekly: Number(value.retention.weekly),
                    monthly: Number(value.retention.monthly),
                    yearly: Number(value.retention.yearly),
                },
                ltr: value.ltr === 'yes',
                dtc: value.dtc === 'yes',
            }));
            dispatch(updateSources(payload));
            if (isEditDialog) {
                close();
            } else {
                dispatch(nextStep());
            }
        },
        [dispatch, close],
    );

    return {
        dispatch,
        state,
        handleAddSource,
        handleSubmit,
    };
};

export default useSources;
