import React, { memo, ReactElement } from 'react';
import 'components/forms/Input/InputGroup/inputGroup.scss';

type Props = {
    title: string;
    tooltip?: ReactElement<JSX.Element>;
};

const InputGroupComponent = ({ title, tooltip }) => (
    <div className="input-group">
        <h2 className="input-group__title">{title}</h2>
        {tooltip}
    </div>
);

InputGroupComponent.defaultProps = {
    tooltip: null,
};

export default memo<Props>((props) => <InputGroupComponent {...props} />);
