import { SkuDefaultValuesType } from 'store/slices/sku/sku.types';
import { SourceType } from './store/slices/sources/sources.types';

export type Retention = {
    daily: number;
    weekly: number;
    monthly: number;
    yearly: number;
};

export enum WorkloadTypesEnum {
    SELECT = 'Select',
    NAS = 'NAS',
    VMWARE = 'VMware',
    WINDOWS = 'Windows',
    LINUX = 'Linux',
    MSSQL = 'MS-SQL',
    ORACLE = 'Oracle',
    HYPERV = 'Hyper-V',
    ARCHIVEFSNAS = 'Archive (FS/NAS)',
    NUTANIXAHV = 'Nutanix AHV',
    EC2 = 'EC2',
    SAPHANA = 'SAP HANA',
    AZUREVM = 'Azure VM',
}

export enum WorkloadEnum {
    SELECT = 'select',
    NAS = 'nas',
    VMWARE = 'vMware',
    WINDOWS = 'windows',
    LINUX = 'linux',
    MSSQL = 'msSql',
    ORACLE = 'oracle',
    HYPERV = 'hyperV',
    ARCHIVEFSNAS = 'archiveFsNas',
    NUTANIXAHV = 'nutanixAhv',
    EC2 = 'ec2',
    SAPHANA = 'sapHana',
    AZUREVM = 'azureVm',
}

export type ApiInputs = {
    sources: Array<SourceType>;
    duration: number;
} & SkuDefaultValuesType;

export type AllSourcesDataTypes = string;

export type CalculatedDataObject = {
    dailyData: Array<dailyMonthlyData>;
    monthlyData: Array<dailyMonthlyData>;
};

export type dailyMonthlyData = {
    total?: number;
    target?: number;
    creditsMonthly?: number;
    cumulatedCredits?: number;
    totalDaily?: number;
    targetTbDaily?: number;
};

export type CalculatedDataTypes = 'ltr' | 'noLtr' | 'dtc' | 'allSources';

export type CalculatedData = { [T in CalculatedDataTypes]?: CalculatedDataObject };

export type CalculatedDataResponse = {
    results: CalculatedData;
};

export type AllSourcesDataResponse = {
    results: CalculatedData;
};

export type workloads = WorkloadTypesEnum;
export type IteratorType = number[] | string[];

export type CompareGraphKeys = 'month' | 'Monthly Credits LTR' | 'Monthly Credits Standard' | 'Total Credit Cumul';
export type CompreGraphHookType = { [T in CompareGraphKeys]: number };
export type TargetGraphKeys =
    | 'month'
    | 'Phoenix Storage LTR'
    | 'Phoenix Storage Standard (TB)'
    | 'Storage LTR'
    | 'Storage Standard (TB)';
export type TargetGraphHookType = Partial<{ [T in TargetGraphKeys]: number }>;
export type SummaryGraphKeys = 'year' | 'Average Source+Changes (TB)' | 'Phoenix Storage (TB)' | 'Storage (TB)';
export type SummaryGraphHookType = Partial<{ [T in SummaryGraphKeys]: number }>;

export type GraphsTransformArgs<T> = (
    el: dailyMonthlyData,
    index: number,
    results: CalculatedData,
    isDell?: boolean,
) => T;
export type SummaryGraphsTransformArgs<T> = (el: number, index: number, results: CalculatedData, isDell?: boolean) => T;

export type TilesType = {
    label: string;
    value: number | string;
    isEditable: boolean;
};

export type BackupData = {
    sourceChanges: number[];
    targetData: number[];
    creditsLtr: number[];
    creditsNoLtr: number[];
    dtc: number[];
    creditsTotal: number[];
};
