import React from 'react';
import 'layout/layout.scss';
import Navbar from 'components/Navbar/Navbar';
import DellNavbar from 'components/Navbar/DellNavbar';

import Footer from 'components/Footer/Footer';
import { useRootState } from 'store/StateProvider';

const Layout = ({ children }) => {
    const {
        location: { isDell },
    } = useRootState();

    return (
        <div className="layout">
            {isDell ? <DellNavbar /> : <Navbar />}
            {children}
            <div className="footer-wrapper">
                <Footer />
            </div>
        </div>
    );
};
export default Layout;
