import React, { useCallback } from 'react';
import Close from '@material-ui/icons/Close';
import './assumptions-drawer.scss';
import { useRootState, useRootDispatch } from 'store/StateProvider';
import { save, closeDrawer, edit, openDialogBox } from 'store/slices/assumptions/assumptionsActions';
import { Formik, Form } from 'formik';
import Drawer from '@material-ui/core/Drawer';
import { parseAssumptionsPayloadToNumericValues } from 'pdf/utils';
import useSources from 'views/Sources/hooks/useSources';
import AssumptionsCloseDialog from './AssumptionsCloseDialog';

import AssumptionsButtons from './AssumptionsButtons';
import DedupeTable from './DedupeTable';
import LtrTable from './LtrTable';
import DtcTable from './DtcTable';
import PricingTable from './PricingTable';
import ChangesTable from './ChangesTable';

const AssumptionsDrawer = (): JSX.Element => {
    const {
        assumptions,
        location: { isDell },
    } = useRootState();
    const dispatch = useRootDispatch();
    const { dedupe, ltr, dtc, pricing, changeRates, isEditing, isOpen } = assumptions;
    const formData = {
        dedupe,
        ltr,
        dtc,
        pricing,
        changeRates: {
            ...changeRates,
            weeklyCap: +(changeRates.weeklyCap * 100).toFixed(2),
            monthlyCap: +(changeRates.monthlyCap * 100).toFixed(2),
            yearlyCap: +(changeRates.yearlyCap * 100).toFixed(2),
        },
    };
    const {
        handleSubmit,
        state: {
            sourcesData: { sources },
        },
    } = useSources();

    const handleCloseDrawer = useCallback(
        (values) => {
            let hasChanged = false;
            Object.keys(formData).every((item) => {
                const initialValue = formData[item];
                const currentValues = values[item];
                Object.keys(initialValue).every((value) => {
                    if (typeof initialValue[value] !== 'object' || !Array.isArray(initialValue[value])) {
                        if (initialValue[value] !== parseFloat(currentValues[value])) {
                            hasChanged = true;
                        }
                    }
                    return !hasChanged;
                });
                return !hasChanged;
            });

            if (isEditing && hasChanged) {
                dispatch(openDialogBox(true));
            } else {
                dispatch(closeDrawer());
                dispatch(edit(false));
            }
        },
        [isEditing, dispatch, formData],
    );

    return (
        isOpen && (
            <Formik
                initialValues={formData}
                enableReinitialize
                onSubmit={(values) => {
                    dispatch(save(parseAssumptionsPayloadToNumericValues(values)));
                    dispatch(edit(false));
                    const newSources = sources.map((source) => ({
                        ...source,
                        dedupe: +values.dedupe[source.type],
                        dtc: source.dtc ? 'yes' : 'no',
                        ltr: source.ltr ? 'yes' : 'no',
                        size: `${source.size}`,
                    }));
                    handleSubmit(newSources, true);
                }}
            >
                {({ resetForm, values }) => (
                    <Drawer
                        anchor="right"
                        open={isOpen}
                        onBackdropClick={() => {
                            handleCloseDrawer(values);
                        }}
                    >
                        <div className="drawer">
                            <AssumptionsCloseDialog />
                            <Close
                                className="drawer-close-icon"
                                color="disabled"
                                onClick={() => {
                                    handleCloseDrawer(values);
                                }}
                            />
                            <div className="drawer-content-header">
                                <p id="drawer-header">Assumptions</p>
                                <div id="drawer-header-fill" />
                            </div>
                            <Form>
                                <AssumptionsButtons isEditing={isEditing} resetForm={resetForm} />
                                <DedupeTable />
                                <LtrTable />
                                {!isDell && <DtcTable />}
                                <PricingTable />
                                <ChangesTable />
                            </Form>
                        </div>
                    </Drawer>
                )}
            </Formik>
        )
    );
};

export default AssumptionsDrawer;
