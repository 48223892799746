import { getType, ActionType } from 'typesafe-actions';
import * as Actions from './locationActions';
import { locationInitialStore } from './location.initialState';

const { updateCurrentLocationRoute } = Actions;

export const locationReducer = (state: LocationState, action: LocationActionTypes): LocationState => {
    switch (action.type) {
        case getType(updateCurrentLocationRoute): {
            const location = action.payload;
            const isDell = location === 'dell';

            return {
                ...state,
                currentLocation: location,
                isDell,
            };
        }
        default: {
            return state;
        }
    }
};

export type LocationActionTypes = ActionType<typeof Actions>;
export type LocationState = typeof locationInitialStore;
