import React from 'react';

import TargetGraph from 'views/ProgressGraphs/TargetGraph/TargetGraph';
import CompareGraph from 'views/ProgressGraphs/CompareGraph/CompareGraph';

import './progressGraphs.scss';

const ProgressGraphs = (): JSX.Element => (
    <div className="progressGraphsWrapper">
        <TargetGraph />
        <CompareGraph />
    </div>
);

export default ProgressGraphs;
