import React, { useEffect } from 'react';
import { CircularProgress, Box } from '@material-ui/core';
import { useRootState, useRootDispatch } from 'store/StateProvider';
import LinearStepper from 'components/LinearStepper/LinearStepper';
import Sources from 'views/Sources/Sources';
import Sku from 'views/Sku/SKUForm/SKUForm';
import Results from 'views/ResultsTabs/Tabs';
import { fetchAuthSession, fetchUserAttributes } from 'aws-amplify/auth';
import { saveLoginMethod, saveToken, saveUserInfo } from 'store/slices/auth/authActions';
import { useApi } from 'hooks/useApi';
import { setDefaultAssumptions, updateSku } from 'store/slices/actions';
import { AssumptionsValues } from 'store/slices/assumptions/assumptions.types';
import './home.scss';
import { Redirect } from 'react-router-dom';
import { routes } from '../../config';

const STEPS = {
    dell: {
        0: <Sources />,
        1: <Results />,
    },
    druva: {
        0: <Sources />,
        1: <Sku />,
        2: <Results />,
    },
};

type StepContentTypes = {
    step: number;
    currentLocation: string;
};

const getStepContent = ({ step, currentLocation }: StepContentTypes) => STEPS[currentLocation][step];

const Home: React.FC = () => {
    const {
        steps: { step },
        auth: { token, isAuthenticated, loginMethod },
        location: { isDell, currentLocation },
    } = useRootState();

    const dispatch = useRootDispatch();
    const { response, loading } = useApi<boolean, { assumptions: AssumptionsValues }>({
        method: 'GET',
        url: 'assumptions',
        payload: Boolean(token),
    });

    useEffect(() => {
        if (step === 0 && isDell) {
            dispatch(
                updateSku({
                    plan: 'elite',
                    cloud: 'publicCloud',
                    discounts: [0, 0, 0],
                }),
            );
        }
    }, [step, dispatch, isDell]);

    useEffect(() => {
        (async function getToken() {
            dispatch(saveToken((await fetchAuthSession()).tokens.idToken.toString()));
            const userAttributes = await fetchUserAttributes();
            const stringyfiedAttr = JSON.stringify(userAttributes);
            const { identities = '{}' } = JSON.parse(stringyfiedAttr);
            let providerName = '';
            if (identities && JSON.parse(identities)[0]) {
                providerName = JSON.parse(identities)[0].providerName || '';
            }

            if (providerName) {
                dispatch(
                    saveLoginMethod(providerName.includes('okta') || providerName.includes('OKTA') ? 'okta' : 'ping'),
                );
            } else {
                dispatch(saveLoginMethod('normal'));
            }
            if (userAttributes.given_name && userAttributes.family_name) {
                dispatch(
                    saveUserInfo({
                        email: userAttributes.email,
                        firstName: userAttributes.given_name,
                        familyName: userAttributes.family_name,
                    }),
                );
            } else {
                dispatch(
                    saveUserInfo({
                        email: userAttributes.email,
                        firstName: '',
                        familyName: '',
                    }),
                );
            }
        })();
    }, [dispatch]);

    useEffect(() => {
        if (response?.assumptions) {
            dispatch(setDefaultAssumptions(response.assumptions));
        }
    }, [token, response, dispatch]);

    // TODO: (P.C) Would be good to move this to separate file along with all protected routes checking.
    if (!isAuthenticated) return <Redirect to={routes[currentLocation].default} />;
    // console.log({ loginMethod });
    if (loginMethod === 'okta' && currentLocation !== 'druva') return <Redirect to={routes.druva.home} />;
    if (loginMethod === 'ping' && currentLocation !== 'dell') return <Redirect to={routes.dell.home} />;

    return (
        <div className="home">
            {loading ? (
                <Box mt={30}>
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <div className="linear-stepper-wrapper">
                        <LinearStepper />
                    </div>

                    <div className="sources-form-wrapper">{getStepContent({ step, currentLocation })}</div>
                </>
            )}
        </div>
    );
};

export default Home;
