import React from 'react';
import { XAxis, YAxis, CartesianGrid, Tooltip, Area, Legend, Label, Bar, ComposedChart } from 'recharts';

import { useCurrentTableData } from 'hooks/useCurrentTableData';
import { CompreGraphHookType, GraphsTransformArgs, dailyMonthlyData } from 'types';
import { useRootState } from 'store/StateProvider';
import RenderLegend from '../RenderLegend';

const dataType = 'monthlyData';

const transformForCompareGraph: GraphsTransformArgs<CompreGraphHookType> = (el: dailyMonthlyData, index, results) => ({
    month: index + 1,
    'Monthly Credits LTR': Math.round(results.ltr[dataType][index]?.creditsMonthly * 100) / 100 || 0,
    'Monthly Credits Standard': Math.round(results.noLtr[dataType][index]?.creditsMonthly * 100) / 100 || 0,
    'Monthly Credits Archive': Math.round(results.dtc[dataType][index]?.creditsMonthly * 100) / 100 || 0,
    'Total Credit Cumul': Math.round(el.cumulatedCredits * 100) / 100,
});

const LegendCompoent = (payload) => <RenderLegend payload={payload} />;

const CompareGraph = (): JSX.Element => {
    const {
        sourcesData: { tcoYear },
    } = useRootState();

    const { currentSourceData } = useCurrentTableData<CompreGraphHookType>(
        tcoYear,
        0,
        dataType,
        transformForCompareGraph,
    );
    const [lastMonthCreditObject] =
        currentSourceData && !!currentSourceData.length ? currentSourceData.slice(-1) : [{}];

    return (
        <ComposedChart width={1000} height={450} data={currentSourceData} margin={{ bottom: 50, left: 50, right: 50 }}>
            <XAxis dataKey="month" axisLine={false} tickLine={false} tickMargin={20}>
                <Label value="Month" style={{ fill: '#000' }} offset={-30} position="insideBottomLeft" />
            </XAxis>
            <YAxis yAxisId="monthlyCredits" axisLine={false} orientation="left" tickLine={false} tickMargin={20}>
                <Label value="Credits" style={{ fill: '#000' }} offset={15} position="bottom" />
            </YAxis>
            <YAxis
                dataKey="Total Credit Cumul"
                yAxisId="creditsCumul"
                orientation="right"
                axisLine={false}
                tickLine={false}
                tickMargin={20}
                stroke="rgb(0, 40, 76)"
            />
            <Tooltip />
            <Legend
                verticalAlign="top"
                wrapperStyle={{ top: 0 }}
                height={50}
                content={({ payload }) => LegendCompoent(payload)}
            />
            <CartesianGrid stroke="#f5f5f5" />

            {lastMonthCreditObject['Monthly Credits LTR'] && (
                <Bar
                    yAxisId="monthlyCredits"
                    dataKey="Monthly Credits LTR"
                    barSize={20}
                    fill="rgba(255,153,0,1)"
                    stackId="stack"
                />
            )}

            {lastMonthCreditObject['Monthly Credits Standard'] && (
                <Bar
                    yAxisId="monthlyCredits"
                    dataKey="Monthly Credits Standard"
                    barSize={20}
                    fill="rgba(20,110,180,1)"
                    stackId="stack"
                />
            )}

            {lastMonthCreditObject['Monthly Credits Archive'] && (
                <Bar
                    yAxisId="monthlyCredits"
                    dataKey="Monthly Credits Archive"
                    barSize={20}
                    fill="rgba(105, 205, 10, 0.2)"
                    stackId="stack"
                />
            )}

            <Area
                type="monotone"
                dataKey="Total Credit Cumul"
                yAxisId="creditsCumul"
                stroke="rgb(0, 40, 76)"
                fillOpacity={1}
                fill="rgba(0, 40, 76, 0.05)"
                dot={{ fill: 'rgb(0, 40, 76)', strokeWidth: 2 }}
            />
        </ComposedChart>
    );
};

export default CompareGraph;
