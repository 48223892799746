import React, { memo } from 'react';
import { InputTitle, InputGroup } from 'components/forms';
import { useRootState, useRootDispatch } from 'store/StateProvider';
import TooltipIcon from 'components/Tooltip/TooltipIcon';
import SelectTCOYear from 'components/SelectTCOYear/SelectTCOYear';
import { updateTCOYear } from 'store/slices/actions';
import SourcesForm from './SourcesForm/SourcesForm';
import './Sources.scss';
import CustomerDetails from './CustomerDetails';

type Props = {
    isEditDialog?: boolean;
};

const SourcesComponent = ({ isEditDialog }) => {
    const {
        sourcesData: { count, tcoYear },
        location: { isDell },
    } = useRootState();

    const dispatch = useRootDispatch();

    const handleChange = (value: number) => {
        // Update selected tcoYear
        dispatch(updateTCOYear(value));
    };

    return (
        <>
            {isEditDialog ? null : (
                <>
                    <CustomerDetails />
                    <div className="sources-hint">
                        <h3>Add sources</h3>
                        <span>
                            Please add the details of the data sources planned to be protected with{' '}
                            {isDell ? 'Dell EMC' : 'Druva'}.
                        </span>
                    </div>
                    <SelectTCOYear tcoYear={tcoYear} handleValueChange={handleChange} />
                </>
            )}
            <div className="sources">
                <div className="source">
                    <InputGroup title={`Sources (${count})`} />
                </div>
                <div className="details">
                    <InputGroup title="Server Details" />
                    <div className="details-sub-header">
                        <InputTitle text="Workload Type" />
                        <InputTitle text="Dedupe Ratio" />
                        <InputTitle text="Data Size (TB)" />
                    </div>
                </div>
                <div className="dataChange">
                    <InputGroup title="Data Change" />
                    <div className="dataChange-sub-header">
                        <InputTitle text="Annual Growth (%)" />
                        <InputTitle text="Daily Growth (%)" />
                        <InputTitle text="Daily Change (%)" />
                        <InputTitle text="Deployment Period (# Days)" />
                    </div>
                </div>
                <div className="retention">
                    <InputGroup title="Retention" />
                    <div className="row">
                        <InputTitle text="Dailies" />
                        <InputTitle text="Weeklies" />
                        <InputTitle text="Monthlies" />
                        <InputTitle text="Yearlies" />
                        <InputTitle
                            text="LTR Tiering"
                            tooltip={
                                <TooltipIcon
                                    title="LTR - Long Term Retention - Enable this if you want to move the data to a Cold tier."
                                    arrow
                                    placement="left"
                                />
                            }
                        />
                    </div>
                </div>
            </div>

            <SourcesForm isEditDialog={isEditDialog} />
        </>
    );
};

SourcesComponent.defaultProps = {
    isEditDialog: false,
};

export default memo<Props>((props) => <SourcesComponent {...props} />);
