import { dailyMonthlyData } from 'types';

export const createTableObj = (
    arr: dailyMonthlyData[],
    index: number,
): { total: number; target: number; creditsMonthly: number; cumulatedCredits: number } => ({
    total: Math.round(arr[index]?.total * 100) / 100 || 0,
    target: Math.round(arr[index]?.target * 100) / 100 || 0,
    creditsMonthly: Math.round(arr[index]?.creditsMonthly * 100) / 100 || 0,
    cumulatedCredits: Math.round(arr[index]?.cumulatedCredits * 100) / 100 || 0,
});
