const routes = {
    default: '/',

    druva: {
        default: '/druva',
        home: '/druva/home',
        login: '/druva/login',
        signUp: '/druva/sign-up',
        forgotPassword: '/druva/forgot-password',
    },
    dell: {
        default: '/dell',
        home: '/dell/home',
        login: '/dell/login',
        signUp: '/dell/sign-up',
        forgotPassword: '/dell/forgot-password',
    },
};

export default routes;
