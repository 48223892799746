export const SOURCE_TABLE_PRIMARY_HEADERS = {
    sources: 'Sources',
    serverDetails: 'Server details',
    dataChange: 'Data change',
    retention: 'Retention',
};
export const SOURCE_TABLE_SECONDARY_HEADERS = {
    workload: 'Workload type',
    dedupe: 'Dedupe Ratio',
    dataSize: 'Data size (TB)',
    annualGrowth: 'Annual growth (%)',
    dailyGrowth: 'Daily growth (%)',
    dailyChange: 'Daily change (%)',
    deploymentPeriod: 'Deployment Period (# Days)',
    dailies: 'Dailies',
    weeklies: 'Weeklies',
    monthlies: 'Monthlies',
    yearlies: 'Yearlies',
    ltrTiering: 'LTR Tiering?',
};

export const SUMMARY_TABLE_PRIMARY_HEADERS = {
    standard: 'Standard',
    ltr: 'LTR',
    archive: 'Archive',
    allSources: 'All Sources',
};

export const SUMMARY_TABLE_SECONDARY_HEADERS = {
    standardSourcePlusChange: 'S+C Standard (TB)',
    phoenixStorageStandard: 'Phoenix Storage Standard (TB)',
    standardCreditsCumul: 'Credits Cumul Standard',
    standardMonthlyCredits: 'Monthly Credits Standard',
    ltrSourcePlusChange: 'S+C LTR (TB)',
    phoenixStorageLtr: 'Phoenix Storage LTR (TB)',
    ltrCreditsCumul: 'Credits Cumul LTR',
    ltrMonthlyCredits: 'Monthly Credits LTR',
    archiveSourcePlusChange: 'S+C Archive(TB)',
    phoenixStorageArchive: 'Phoenix Storage Archive(TB)',
    archiveCreditsCumul: 'Credits Cumul Archive',
    archiveMonthlyCredits: 'Monthly Credits Archive',
    totalSourcePlusChange: 'Standard S+C (TB)',
    phoenixStorageTotal: 'Phoenix Storage (TB)',
    totalCreditsCumul: 'Total Credits Cumul',
    totalMonthlyCredits: 'Total Monthly Credits',
};

export const BACKUP_TABLE_TITLES = {
    averageSourcePlusChange: 'Average Source+Changes (TB)',
    storage: 'Storage (TB)',
    standardCredits: 'Credits Standard',
    ltrCredits: 'Credits LTR',
    archiveCredits: 'Credits Archive',
    totalCredits: 'Credits Total',
};
