import React, { memo } from 'react';
import Input from 'components/forms/Input/Input';
import { SkuDefaultValuesType } from 'store/slices/sku/sku.types';
import SectionLabel from 'components/SectionLabel/SectionLabel';
import { FieldArray } from 'formik';
import 'views/Sku/sku.scss';

type DiscountsProps = {
    values: SkuDefaultValuesType;
    defaults: SkuDefaultValuesType;
};

const Discounts = memo(({ values, defaults: { discounts } }: DiscountsProps) => {
    const Inputs =
        values.discounts && values.discounts.length > 0
            ? values.discounts.map((discount, index) => {
                  const id = index;
                  return (
                      <div key={id} className="form__discount">
                          <p>{`Year ${index + 1}`}</p>
                          <Input name={`discounts.${index}`} type="number" defaultValue={discounts[index]} isEditable />
                      </div>
                  );
              })
            : null;

    return (
        <div className="form__form-row row-4">
            <SectionLabel>Discounts (%)</SectionLabel>
            <FieldArray name="discounts" render={() => Inputs} />
        </div>
    );
});

export default Discounts;
