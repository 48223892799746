import * as Actions from 'store/slices/steps/stepsActions';
import { getType, ActionType } from 'typesafe-actions';
import { stepsInitialState } from './steps.initialState';

const { nextStep, prevStep, resetSteps } = Actions;

export const stepsReducer = (state: StepsState, action: StepsActionTypes): StepsState => {
    switch (action.type) {
        case getType(nextStep): {
            return { ...state, step: state.step + 1 };
        }
        case getType(prevStep): {
            return { ...state, step: state.step - 1 };
        }
        case getType(resetSteps): {
            return { ...state, step: 0 };
        }
        default: {
            return state;
        }
    }
};
export type StepsActionTypes = ActionType<typeof Actions>;
export type StepsState = typeof stepsInitialState;
