import React from 'react';
import { useRootState } from 'store/StateProvider';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import { Connector, LabelStyled, useStepIconStyles, useStyles } from 'components/LinearStepper/styles';
import { StepsEnum } from 'store/slices/steps/steps.types';

interface StepIconProps {
    active: boolean;
    completed: boolean;
    icon: number;
}
const StepIcon = ({ active, completed, icon }: StepIconProps) => {
    const classes = useStepIconStyles({});
    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
            })}
        >
            {completed ? <div className={classes.completed}>{icon}</div> : <div className={classes.circle}>{icon}</div>}
        </div>
    );
};

const LinearStepper: React.FC = () => {
    const classes = useStyles({});
    const {
        steps: { step, steps },
        location: { isDell: hideSKU },
    } = useRootState();

    const filteredSteps = hideSKU ? steps.filter(filteredStep => filteredStep !== StepsEnum.SKU_DISCOUNTS) : steps;

    return (
        <div className={classes.root}>
            <Stepper activeStep={step} connector={<Connector />} className={classes.root}>
                {filteredSteps.map(label => (
                    <Step key={label}>
                        <LabelStyled StepIconComponent={StepIcon}>{label}</LabelStyled>
                    </Step>
                ))}
            </Stepper>
        </div>
    );
};

export default LinearStepper;
