import React, { ReactElement } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useRootState } from 'store/StateProvider';

const ProtectedRoute = ({
    exact,
    path,
    children,
}: {
    exact: boolean;
    path: string;
    children: ReactElement;
}): ReactElement => {
    const {
        auth: { isAuthenticated },
    } = useRootState();
    const defaultPath = `/${path.split('/')[1]}`;

    return (
        <Route exact={exact} path={path} render={() => (isAuthenticated ? children : <Redirect to={defaultPath} />)} />
    );
};

export default ProtectedRoute;
