import React, { memo } from 'react';
import 'components/Navbar/navbar.scss';
import { useRootDispatch, useRootState } from 'store/StateProvider';

import { logOut } from 'store/slices/auth/authActions';
import { signOut } from 'aws-amplify/auth';
import Popover from '@material-ui/core/Popover';
import { logoutButton } from 'components/Button/styles';
import Button from 'components/Button/Button';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

const AccountDetailsPopover = memo(() => {
    const dispatch = useRootDispatch();
    const {
        auth: { email, firstName, familyName },
    } = useRootState();

    const handleLogOut = async () => {
        // TODO :
        // We can create custom hook for this (it is better when you write units test for this ) and definitely for handleLogOut we should use useCallback hook
        await signOut();
        dispatch(logOut());
    };

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const isPopoverOpen = Boolean(anchorEl);
    return (
        <>
            <AccountCircleIcon fontSize="large" onClick={(event) => handleClick(event)} />
            <Popover
                open={isPopoverOpen}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div className="popover">
                    <div className="popover__info">
                        {firstName + familyName && (
                            <div>
                                {firstName} {familyName}
                            </div>
                        )}
                        <div className="popover__info__email">{email}</div>
                    </div>
                    <div>
                        <Button type="button" handleClick={handleLogOut} style={logoutButton}>
                            Signout
                        </Button>
                    </div>
                </div>
            </Popover>
        </>
    );
});

export default AccountDetailsPopover;
