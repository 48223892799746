import React from 'react';
import Tiles from 'components/Tiles/Tiles';
import './summary.scss';
import BackupTable from 'views/ResultsTabs/Summary/BackupTable/BackupTable';
import SourceSummaryTable from 'views/ResultsTabs/Summary/SourceSummaryTable/SourceSummaryTable';
import 'views/ResultsTabs/Summary/summary.scss';

const Summary = (): React.ReactElement => (
    <div className="summary">
        <Tiles />
        <SourceSummaryTable />
        <BackupTable />
    </div>
);

export default Summary;
