import React, { useState, useCallback, useEffect } from 'react';
import { withStyles, FormControl, MenuItem } from '@material-ui/core';
import { SelectStyled, useDropdownStyles } from 'components/forms/Select/styles';
import { YEARS_LABEL_ARRAY } from 'config/setup/constants';

type Props = {
    style?: {
        paddingLeft?: string;
        paddingRight?: string;
    };
    tcoYear: number;
    handleValueChange: (value: number) => void;
};

const SelectTCOYear = ({ tcoYear, handleValueChange, style }: Props): JSX.Element => {
    const [selectedTcoYear, setSelectedTcoYear] = useState(tcoYear);

    const handleChange = useCallback(
        (event) => {
            setSelectedTcoYear(event.target.value);
            handleValueChange(event.target.value);
        },
        [handleValueChange],
    );

    useEffect(() => {
        setSelectedTcoYear(tcoYear);
    }, [tcoYear]);

    const FormControlStyled = withStyles({
        root: {
            position: 'relative',
            minWidth: '10rem',
        },
    })(FormControl);
    const { dropdownStyle } = useDropdownStyles({});

    return (
        <div style={style} className="select-tco">
            <span>Select Years to calculate TCO</span>
            <FormControlStyled variant="outlined">
                <SelectStyled
                    value={selectedTcoYear}
                    MenuProps={{ classes: { paper: dropdownStyle } }}
                    onChange={handleChange}
                >
                    {YEARS_LABEL_ARRAY.map((label, index) => (
                        <MenuItem key={`${label}`} value={index + 1}>
                            {label}
                        </MenuItem>
                    ))}
                </SelectStyled>
            </FormControlStyled>
        </div>
    );
};

SelectTCOYear.defaultProps = {
    style: {},
};

export default SelectTCOYear;
