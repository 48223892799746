import React from 'react';
import Button from '@material-ui/core/Button';
/* eslint import/no-unresolved: [2, { ignore: ['csstype'] }] */
import { Properties } from 'csstype';

interface Props {
    handleClick?: () => void;
    isDisabled?: boolean;
    style?: Properties;
    children: React.ReactNode;
    type?: 'button' | 'submit' | 'reset';
    className?: string;
}
const MaterialButton = ({ handleClick, isDisabled, style, children, type, className }: Props): JSX.Element => (
    <Button disabled={isDisabled} type={type} onClick={handleClick} style={style} className={className}>
        {children}
    </Button>
);

MaterialButton.defaultProps = {
    handleClick: () => {},
    isDisabled: false,
    style: null,
    type: 'button',
    className: null,
};

export default MaterialButton;
