import { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import isEqual from 'lodash/isEqual';
import { useRootState } from '../store/StateProvider';

interface ApiResponse<T> {
    response: T | null;
    loading: boolean;
    error: any;
}

const BASE_URL = 'https://2icvqjs65m.execute-api.us-east-1.amazonaws.com/dev/';

export function useApi<T, T1>({
    method,
    url,
    payload,
}: {
    method: 'GET' | 'POST';
    payload?: T;
    url: string;
}): ApiResponse<T1> {
    const {
        auth: { token },
    } = useRootState();
    const [response, setResponse] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<any>(null);

    // Define payloadRef using useRef
    const payloadRef = useRef({});

    const fetchData = useCallback(async () => {
        try {
            const results = await fetch(`${BASE_URL}${url}`, {
                method,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                },
                ...(method !== 'GET' ? { body: JSON.stringify(payload) } : {}),
            });
            const data = await results.json();
            setResponse(data);
            setLoading(false);
        } catch (e) {
            setError(e);
            setLoading(false);
        }
    }, [method, payload, token, url]);

    useEffect(() => {
        if (token && !isEqual(payload, payloadRef.current)) {
            payloadRef.current = payload; // Update the reference
            setLoading(true);
            fetchData();
        }
    }, [token, payload, fetchData]);

    const memoizedApiResponse = useMemo(() => ({ response, loading, error }), [response, loading, error]);

    return memoizedApiResponse;
}
