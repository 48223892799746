import React, { memo } from 'react';
import 'components/Footer/footer.scss';

const Footer = memo(() => (
    <footer className="footer">
        <div className="footer-section-druva">
            <h4>
                <span>Powered by</span>
                <img src="/druvalogo.svg" alt="Druva" />
            </h4>
        </div>
        <div className="druva-urls">
            <nav>
                <ul>
                    <li>
                        <a href="https://www.dell.com/learn/us/en/uscorp1/policies-privacy ">Privacy Policy</a>
                    </li>
                    <li>
                        <a href="https://www.dell.com/support/ ">Support</a>
                    </li>
                </ul>
            </nav>
        </div>
        <div className="copyright">
            <p>© 2021 Druva Inc.</p>
        </div>
    </footer>
));

export default Footer;
