import { useState, useEffect } from 'react';
import { useRootState } from 'store/StateProvider';

export function usePdfDialogData(
    changeTimeRange: React.Dispatch<React.SetStateAction<number>>,
    changePriceInformation: React.Dispatch<React.SetStateAction<boolean>>,
    changeIsCustomerInfoVisible: React.Dispatch<React.SetStateAction<boolean>>,
): {
    pdfReady: boolean;
    timeRangeString: string;
    changeTimeRangeString: React.Dispatch<React.SetStateAction<string>>;
    changePdfReady: React.Dispatch<React.SetStateAction<boolean>>;
    priceInformationString: string;
    isCustomerInfoVisibleString: string;
    changePriceInformationString: React.Dispatch<React.SetStateAction<string>>;
    changeIsCustomerInfoVisibleString: React.Dispatch<React.SetStateAction<string>>;
} {
    const {
        sourcesData: { tcoYear },
    } = useRootState();
    const [pdfReady, changePdfReady] = useState<boolean>(false);
    const [timeRangeString, changeTimeRangeString] = useState<string>(`${tcoYear}`);
    const [priceInformationString, changePriceInformationString] = useState<string>('enabled');
    const [isCustomerInfoVisibleString, changeIsCustomerInfoVisibleString] = useState<string>('yes');

    // set the selected checkbox of timeRange in PDFDialog to latest selected tcoYear
    useEffect(() => {
        changeTimeRangeString(`${tcoYear}`);
    }, [tcoYear]);

    useEffect(() => {
        changePdfReady(false);
        changeTimeRange(parseInt(timeRangeString, 10));
        // TODO: change it to not use delays
        setTimeout(() => changePdfReady(true), 2000);
    }, [timeRangeString, changeTimeRange]);

    useEffect(() => {
        changePdfReady(false);
        changePriceInformation(priceInformationString === 'enabled');
        // TODO: change it to not use delays
        setTimeout(() => changePdfReady(true), 2000);
    }, [priceInformationString, changePriceInformation]);

    useEffect(() => {
        changePdfReady(false);
        changeIsCustomerInfoVisible(isCustomerInfoVisibleString === 'yes');
        // TODO: change it to not use delays
        setTimeout(() => changePdfReady(true), 2000);
    }, [isCustomerInfoVisibleString, changeIsCustomerInfoVisible]);

    return {
        pdfReady,
        timeRangeString,
        changeTimeRangeString,
        changePdfReady,
        priceInformationString,
        isCustomerInfoVisibleString,
        changePriceInformationString,
        changeIsCustomerInfoVisibleString,
    };
}
