import { SUMMARY_TABLE_PRIMARY_HEADERS, SUMMARY_TABLE_SECONDARY_HEADERS } from '../../constants';

export const columns = [
    {
        Header: ' ',
        columns: [
            {
                Header: 'Year',
                accessor: 'year',
            },
            {
                Header: 'Month',
                accessor: 'month',
            },
        ],
    },
    {
        Header: SUMMARY_TABLE_PRIMARY_HEADERS.standard,
        columns: [
            {
                Header: SUMMARY_TABLE_SECONDARY_HEADERS.standardSourcePlusChange,
                accessor: 'noLtr.total',
            },
            {
                Header: SUMMARY_TABLE_SECONDARY_HEADERS.phoenixStorageStandard,
                accessor: 'noLtr.target',
            },
            {
                Header: SUMMARY_TABLE_SECONDARY_HEADERS.standardMonthlyCredits,
                accessor: 'noLtr.creditsMonthly',
            },
            {
                Header: SUMMARY_TABLE_SECONDARY_HEADERS.standardCreditsCumul,
                accessor: 'noLtr.cumulatedCredits',
            },
        ],
    },
    {
        Header: SUMMARY_TABLE_PRIMARY_HEADERS.ltr,
        columns: [
            {
                Header: SUMMARY_TABLE_SECONDARY_HEADERS.ltrSourcePlusChange,
                accessor: 'ltr.total',
            },
            {
                Header: SUMMARY_TABLE_SECONDARY_HEADERS.phoenixStorageLtr,
                accessor: 'ltr.target',
            },
            {
                Header: SUMMARY_TABLE_SECONDARY_HEADERS.ltrMonthlyCredits,
                accessor: 'ltr.creditsMonthly',
            },
            {
                Header: SUMMARY_TABLE_SECONDARY_HEADERS.ltrCreditsCumul,
                accessor: 'ltr.cumulatedCredits',
            },
        ],
    },

    {
        Header: SUMMARY_TABLE_PRIMARY_HEADERS.archive,
        columns: [
            {
                Header: SUMMARY_TABLE_SECONDARY_HEADERS.archiveSourcePlusChange,
                accessor: 'dtc.total',
            },
            {
                Header: SUMMARY_TABLE_SECONDARY_HEADERS.phoenixStorageArchive,
                accessor: 'dtc.target',
            },
            {
                Header: SUMMARY_TABLE_SECONDARY_HEADERS.archiveMonthlyCredits,
                accessor: 'dtc.creditsMonthly',
            },
            {
                Header: SUMMARY_TABLE_SECONDARY_HEADERS.archiveCreditsCumul,
                accessor: 'dtc.cumulatedCredits',
            },
        ],
    },
    {
        Header: SUMMARY_TABLE_PRIMARY_HEADERS.allSources,
        columns: [
            {
                Header: SUMMARY_TABLE_SECONDARY_HEADERS.totalSourcePlusChange,
                accessor: 'allSources.total',
            },
            {
                Header: SUMMARY_TABLE_SECONDARY_HEADERS.phoenixStorageTotal,
                accessor: 'allSources.target',
            },
            {
                Header: SUMMARY_TABLE_SECONDARY_HEADERS.totalMonthlyCredits,
                accessor: 'allSources.creditsMonthly',
            },
            {
                Header: SUMMARY_TABLE_SECONDARY_HEADERS.totalCreditsCumul,
                accessor: 'allSources.cumulatedCredits',
            },
        ],
    },
];
