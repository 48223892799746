import React from 'react';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { TooltipProps } from '@material-ui/core';
import Tooltip from './Tooltip';

type Props = {
    title: string;
    arrow?: boolean;
    placement: TooltipProps['placement'];
};

const TooltipIcon = ({ title, placement, arrow }: Props): JSX.Element => (
    <Tooltip title={title} placement={placement} arrow={arrow}>
        <HelpOutlineIcon />
    </Tooltip>
);

TooltipIcon.defaultProps = {
    arrow: false,
};

export default TooltipIcon;
