import { ActionType, getType } from 'typesafe-actions';
import * as Actions from 'store/slices/assumptions/assumptionsActions';
import { assumptionsInitialState } from './assumptions.initialState';

const { save, openDrawer, closeDrawer, edit, openDialogBox, setDefaultAssumptions, openDrawerAndEdit } = Actions;

export const assumptionsReducer = (state: AssumptionsState, action: AssumptionsActionTypes): AssumptionsState => {
    switch (action.type) {
        case getType(save):
            return {
                ...state,
                dedupe: {
                    ...state.dedupe,
                    ...action.payload.dedupe,
                },
                ltr: {
                    ...state.ltr,
                    ...action.payload.ltr,
                },
                dtc: {
                    ...state.dtc,
                    ...action.payload.dtc,
                },
                pricing: {
                    ...state.pricing,
                    ...action.payload.pricing,
                },
                changeRates: {
                    ...state.changeRates,
                    ...action.payload.changeRates,
                    // These fields are stored here and on backend as floats
                    // in assumptions drawer we need ints to display percetage properly,
                    // so there needs to be conversion
                    weeklyCap: action.payload.changeRates.weeklyCap / 100,
                    monthlyCap: action.payload.changeRates.monthlyCap / 100,
                    yearlyCap: action.payload.changeRates.yearlyCap / 100,
                },
            };
        case getType(edit):
            return { ...state, isEditing: action.payload };
        case getType(openDialogBox):
            return { ...state, isAssumptionsDialogOpen: action.payload };
        case getType(openDrawer):
            return { ...state, isOpen: true };
        case getType(closeDrawer):
            return { ...state, isOpen: false };
        case getType(setDefaultAssumptions):
            return {
                ...state,
                defaultAssumptions: { ...action.payload },
                dedupe: {
                    ...state.dedupe,
                    ...action.payload.dedupe,
                },
                ltr: {
                    ...state.ltr,
                    ...action.payload.ltr,
                },
                dtc: {
                    ...state.dtc,
                    ...action.payload.dtc,
                },
                pricing: {
                    ...state.pricing,
                    ...action.payload.pricing,
                },
                changeRates: {
                    ...state.changeRates,
                    ...action.payload.changeRates,
                },
            };
        case getType(openDrawerAndEdit):
            return {
                ...state,
                isOpen: true,
                isEditing: true,
            };
        default:
            return state;
    }
};

export type AssumptionsActionTypes = ActionType<typeof Actions>;
export type AssumptionsState = typeof assumptionsInitialState;
