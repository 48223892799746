export const buttonStyles = {
    background: '#C8C8C8',
    color: '#ffffff',
    borderRadius: '50%',
    width: '3.4rem',
    height: '3.4rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 'auto',
    padding: '0',
};
