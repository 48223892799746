import React, { memo } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from 'router/Routes';

const App = memo(() => (
    <Router>
        <Routes />
    </Router>
));

export default App;
