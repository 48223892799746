import React from 'react';
import ReactDOM from 'react-dom/client';
import { Amplify } from 'aws-amplify';
import App from './App';

Amplify.configure({
    Auth: {
        Cognito: {
            userPoolId: process.env.REACT_APP_USER_POOL_ID,
            userPoolClientId: process.env.REACT_APP_APP_CLIENT_ID,
            loginWith: {
                oauth: {
                    domain: process.env.REACT_APP_DOMAIN,
                    scopes: ['email', 'openid', 'aws.cognito.signin.user.admin'],
                    redirectSignIn: [process.env.REACT_APP_SIGN_IN],
                    redirectSignOut: [process.env.REACT_APP_SIGN_OUT],
                    responseType: 'token',
                },
            },
        },
    },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
