export type SkuState = {
    defaultValues: SkuDefaultValuesType;
    initialOptions: {
        sku: SkuOptionsEnum[];
        cloud: CloudOptionsEnum[];
    };
} & SkuDefaultValuesType;

export type SkuDefaultValuesType = {
    plan: string;
    cloud: string;
    discounts: number[];
};

export type SkuDiscountValuesType = {
    discounts: number[];
    defaultValues: {
        plan: string;
        cloud: string;
        discounts: number[];
    };
};

export enum SkuOptionsEnum {
    SELECT = 'Select',
    BUSINESS = 'Business',
    ENTERPRISE = 'Enterprise',
    ELITE = 'Elite',
}

export enum CloudOptionsEnum {
    SELECT = 'Select',
    PUBLIC_CLOUD = 'Public Cloud',
    GOV_CLOUD = 'Gov Cloud',
}
