import { getType, ActionType } from 'typesafe-actions';
import * as Actions from './skuActions';
import { skuInitialState } from './sku.initialState';

const { updateSku, resetSku, updateDiscount } = Actions;

export const skuReducer = (state: SkuState, action: SkuActionTypes): SkuState => {
    switch (action.type) {
        case getType(updateSku): {
            return {
                ...state,
                ...action.payload,
            };
        }
        case getType(resetSku): {
            return {
                ...state,
                ...state.defaultValues,
            };
        }
        case getType(updateDiscount): {
            return {
                ...state,
                ...action.payload,
            };
        }
        default: {
            return state;
        }
    }
};

export type SkuActionTypes = ActionType<typeof Actions>;
export type SkuState = typeof skuInitialState;
