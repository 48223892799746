import React, { useState, createContext, ReactElement, useContext } from 'react';
import Dialog from 'components/Dialog/Dialog';

type DialogContextType = {
    title: string;
    content: ReactElement;
    isOpen: boolean;
    open: (dialogContent: ReactElement, dialogTitle?: string) => void;
    close: () => void;
};

export const dialogInitialState = {
    title: '',
    content: null,
    isOpen: false,
    open: (): void => {},
    close: (): void => {},
};
const DialogContext = createContext<DialogContextType>(dialogInitialState);
const { Provider } = DialogContext;

const DialogProvider = ({ children }: { children: React.ReactNode }): ReactElement => {
    const [title, setTitle] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [content, setContent] = useState(null);

    const open = (dialogContent, dialogTitle) => {
        setIsOpen(true);
        setTitle(dialogTitle);
        setContent(dialogContent);
    };
    const close = () => setIsOpen(false);

    return (
        <Provider value={{ content, isOpen, open, close, title }}>
            {children}

            <Dialog />
        </Provider>
    );
};

const useDialogContext = (): DialogContextType => useContext(DialogContext);
export { DialogProvider, useDialogContext };
