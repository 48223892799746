import { withStyles, Select, makeStyles } from '@material-ui/core';

export const SelectStyled = withStyles({
    root: {
        background: '#fff',
        color: '#232F3E',
        borderRadius: '3px',
        '&.error': {
            border: '1px solid #ff0000',
        },
    },
    select: {
        textAlign: 'left',
        fontSize: '1.4rem',

        '&:focus': {
            background: '#fff',
            borderRadius: '3px',
        },
    },
    icon: {
        right: '1rem',
        top: 'calc(50% - 8px)',
    },
    disabled: {
        color: '#afafaf',
        backgroundColor: '#efefef',
    },
})(Select);

export const useDropdownStyles = makeStyles({
    dropdownStyle: {
        paddingTop: '1rem',
        paddingBottom: '1rem',
        borderRadius: '2px',
        boxShadow: 'unset',
        MozBoxShadow: 'unset',
        WebkitBoxShadow: 'unset',
        border: '1px solid #BDC1C5',
        boxSizing: 'border-box',

        '& li': {
            fontSize: '14px',
            fontFamily: 'Lato',
        },
    },
});
