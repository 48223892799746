import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { edit, openDialogBox, closeDrawer } from 'store/slices/assumptions/assumptionsActions';
import { useRootDispatch, useRootState } from 'store/StateProvider';

const AssumptionsCloseDialog = (): JSX.Element => {
    const dispatch = useRootDispatch();
    const {
        assumptions: { isAssumptionsDialogOpen },
    } = useRootState();
    return (
        <Dialog
            open={isAssumptionsDialogOpen}
            onClose={() => dispatch(openDialogBox(false))}
            aria-labelledby="close-dialog-title"
            aria-describedby="close-dialog-description"
            classes={{
                container: 'close-dialog',
            }}
        >
            <DialogTitle id="close-dialog-title">Attention!</DialogTitle>
            <DialogContent>
                <DialogContentText id="close-dialog-description">
                    If you close the Assumptions window without saving, your changes will be lost.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => dispatch(openDialogBox(false))}
                    color="primary"
                    variant="contained"
                    className="close-dialog-cancel"
                >
                    Cancel
                </Button>
                <Button
                    onClick={() => {
                        dispatch(edit(false));
                        dispatch(closeDrawer());
                        dispatch(openDialogBox(false));
                    }}
                    color="primary"
                    variant="contained"
                    className="close-dialog-close"
                >
                    Close Anyway
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AssumptionsCloseDialog;
