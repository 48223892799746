import { createAction } from 'typesafe-actions';
import { AssumptionsValues } from './assumptions.types';

export const save = createAction('SAVE_ASSUMPTIONS', (assumptionsValues: AssumptionsValues) => assumptionsValues)<
    AssumptionsValues
>();

export const openDrawer = createAction('OPEN_DRAWER')<null>();
export const closeDrawer = createAction('CLOSE_DRAWER')<null>();
export const edit = createAction('EDIT', (isEdit: boolean) => isEdit)<boolean>();
export const openDialogBox = createAction('OPEN_DIALOG_BOX', (isOpen: boolean) => isOpen)<boolean>();
export const setDefaultAssumptions = createAction(
    'SET_DEFAULT_ASSUMPTIONS',
    (assumptionsValues: AssumptionsValues) => assumptionsValues,
)<AssumptionsValues>();
export const openDrawerAndEdit = createAction('OPEN_DRAWER_AND_EDIT')<null>();
