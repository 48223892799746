import React, { useCallback, memo } from 'react';
import _ from 'lodash';
import { FieldArrayRenderProps, useField, FormikTouched } from 'formik';
import Input from 'components/forms/Input/Input';
import Select from 'components/forms/Select/Select';
import RowError from 'assets/error.png';
import RowCorrect from 'assets/correct.png';
import { subtractCount } from 'store/slices/sources/sourcesActions';
import { WorkloadEnum } from 'types';
import Edit from 'assets/pencil.png';
import { useRootState } from 'store/StateProvider';
import { openDrawerAndEdit } from 'store/slices/assumptions/assumptionsActions';
import { InputBaseStyled } from 'components/forms/Input/styles';
import { getAnnualGrowth, getDailyGrowth, getDeploymentPeriodBasedOnDataSize } from 'utils/utils';
import useSources from '../../hooks/useSources';
import { sourceValidation } from '../SourcesForm.validation';
import './SourceFields.scss';
import { SourceType } from '../../../../store/slices/sources/sources.types';

const LTRDisabledWorkloadType = [WorkloadEnum.SAPHANA, WorkloadEnum.AZUREVM, WorkloadEnum.ARCHIVEFSNAS, 'select'];
const retentionMappingMoreThanOneYear = {
    daily: 365,
    weekly: 52,
    monthly: 12,
    yearly: 1,
};

const MINIMUM_RETENTION_INPUT_NUMBER = 0;
const MAXIMUM_RETENTION_INPUT_NUMBER = 99999;

const ARCHIVE_MINIMUM_RETENTION_INPUT_NUMBER = 1;

interface Props {
    index: number;
    fieldsArray: FieldArrayRenderProps;
    touched: FormikTouched<{ sources: Array<SourceType> }>;
    showDailyChangeConfirmation: Function;
}
const SourceFields = memo(({ index, fieldsArray, touched, showDailyChangeConfirmation }: Props) => {
    const { assumptions } = useRootState();
    const fieldName = `sources.${index}`;
    const [field, meta] = useField(fieldName);
    const dedupe = assumptions.dedupe[field.value.type] || 0;
    meta.value.dedupe = dedupe;
    const {
        dispatch,
        state: {
            sourcesData: { defaultValues, workloadsOptions },
        },
    } = useSources();
    const workloadDefaultValue = defaultValues[field.value.type];
    const {
        annualGrowth,
        dailyGrowth,
        dailyChange,
        retention: { daily, weekly, monthly, yearly },
    } = workloadDefaultValue;

    const removeSource = useCallback(() => {
        fieldsArray.remove(index);
        dispatch(subtractCount());
    }, [dispatch, fieldsArray, index]);

    const isAnyFieldTouchedAndInvalid = _.intersection(
        (touched?.sources && touched.sources[index] && Object.keys(touched.sources[index])) || [],
        (meta.error && Object.keys(meta.error)) || [],
    );
    const isRowHasSubmitErrors =
        meta.error && Boolean(Object.values(meta.error).filter(Boolean).length) && !!isAnyFieldTouchedAndInvalid.length;
    const isSourceValid = sourceValidation.isValidSync(meta.value);

    // TODO (P.C): toLowerCase() - temporary solution, need to standarize workload strings in both frontend and backend.
    const ltrOptions = ['No', 'Yes'];

    const isRetentionOverAYear = () => {
        if (field.value.retention.weekly >= 52) return true;
        if (field.value.retention.monthly >= 12) return true;
        if (field.value.retention.yearly > 0) return true;
        fieldsArray.form.values.sources[index].ltr = 'no';

        return false;
    };

    const open = useCallback(() => {
        dispatch(openDrawerAndEdit());
    }, [dispatch]);

    const handleWorkloadChange = (e) => {
        const selectedWorkloadDefaultValue = defaultValues[e.target.value];
        fieldsArray.replace(index, {
            ...field.value,
            retention: selectedWorkloadDefaultValue.retention,
            ltr: selectedWorkloadDefaultValue.ltr ? 'yes' : 'no',
            dtc: selectedWorkloadDefaultValue.dtc ? 'yes' : 'no',
            dailyChange: selectedWorkloadDefaultValue.dailyChange,
        });
    };

    const updateDeploymentPeriod = (e) => {
        meta.value.deploymentPeriod = getDeploymentPeriodBasedOnDataSize(e.target.value);
    };

    const checkToEnableLTR = (value, type) => {
        if (type !== 'daily' && +value >= retentionMappingMoreThanOneYear[type]) {
            meta.value.ltr = 'yes';
        }
    };

    const handleDailyChange = (e) => {
        if (meta.value.type === WorkloadEnum.ARCHIVEFSNAS && e.target.value > 0) {
            showDailyChangeConfirmation();
        }
    };

    const handleRetentionChange = (e, type) => {
        if (!LTRDisabledWorkloadType.includes(meta.value.type)) {
            checkToEnableLTR(e.target.value, type);
        }
        if (type === 'yearly' && meta.value.type === WorkloadEnum.ARCHIVEFSNAS) {
            if (e.target.value < 1) {
                e.target.value = 1;
            }
            meta.value.retention.daily = retentionMappingMoreThanOneYear.daily * +e.target.value;
            meta.value.retention.weekly = retentionMappingMoreThanOneYear.weekly * +e.target.value;
            meta.value.retention.monthly = retentionMappingMoreThanOneYear.monthly * +e.target.value;
        }
    };

    const handleGrowthChange = (e, type) => {
        if (e.target.value !== '') {
            if (type === 'annualGrowth') {
                meta.value.dailyGrowth = getDailyGrowth(e.target.value);
            } else {
                meta.value.annualGrowth = getAnnualGrowth(e.target.value);
            }
        }
    };

    return (
        <>
            <div className="counter">
                <div className="source__couter">
                    {isRowHasSubmitErrors && !isSourceValid && <img src={RowError} alt="Source Invalid" />}
                    {isSourceValid && <img src={RowCorrect} alt="Source Valid" />}
                    <h3>{`${index + 1}. `}</h3>
                    <button className="button-remove" type="button" onClick={removeSource}>
                        <span className="cross" />
                    </button>
                </div>
            </div>
            <div className="grid col3">
                <Select
                    clearError
                    name={`${fieldName}.type`}
                    options={workloadsOptions}
                    handleChange={handleWorkloadChange}
                />
                <div className="dedupe">
                    <div title={dedupe} className="truncate">
                        {dedupe}
                    </div>
                    <div>
                        <button className="edit-button" type="button" onClick={open}>
                            <img src={Edit} alt="Edit dedupe" className="edit-icon" />
                        </button>
                    </div>
                </div>
                <Input
                    clearError
                    name={`${fieldName}.size`}
                    type="number"
                    onValueChange={updateDeploymentPeriod}
                    isEditable
                />
            </div>
            <div className="grid data-change-col">
                <Input
                    name={`${fieldName}.annualGrowth`}
                    type="number"
                    isEditable
                    defaultValue={annualGrowth}
                    onValueChange={(e) => handleGrowthChange(e, 'annualGrowth')}
                />
                <Input
                    name={`${fieldName}.dailyGrowth`}
                    type="number"
                    isEditable
                    defaultValue={dailyGrowth}
                    onValueChange={(e) => handleGrowthChange(e, 'dailyGrowth')}
                />
                <Input
                    name={`${fieldName}.dailyChange`}
                    type="number"
                    isEditable
                    defaultValue={dailyChange}
                    onInputBlur={handleDailyChange}
                />
                <Input name={`${fieldName}.deploymentPeriod`} type="number" isEditable />
            </div>
            <div className="grid col5">
                {meta.value.type === WorkloadEnum.ARCHIVEFSNAS ? (
                    <>
                        <InputBaseStyled type="text" disableUnderline disabled defaultValue="N/A" />
                        <InputBaseStyled type="text" disableUnderline disabled defaultValue="N/A" />
                        <InputBaseStyled type="text" disableUnderline disabled defaultValue="N/A" />
                    </>
                ) : (
                    <>
                        <Input
                            name={`${fieldName}.retention.daily`}
                            type="number"
                            isEditable
                            defaultValue={daily}
                            min={MINIMUM_RETENTION_INPUT_NUMBER}
                            max={MAXIMUM_RETENTION_INPUT_NUMBER}
                            disabled={meta.value.type === 'select'}
                            onValueChange={(e) => handleRetentionChange(e, 'daily')}
                        />
                        <Input
                            name={`${fieldName}.retention.weekly`}
                            type="number"
                            isEditable
                            defaultValue={weekly}
                            min={MINIMUM_RETENTION_INPUT_NUMBER}
                            max={MAXIMUM_RETENTION_INPUT_NUMBER}
                            disabled={meta.value.type === 'select'}
                            onValueChange={(e) => handleRetentionChange(e, 'weekly')}
                        />
                        <Input
                            name={`${fieldName}.retention.monthly`}
                            type="number"
                            isEditable
                            defaultValue={monthly}
                            min={MINIMUM_RETENTION_INPUT_NUMBER}
                            max={MAXIMUM_RETENTION_INPUT_NUMBER}
                            disabled={meta.value.type === 'select'}
                            onValueChange={(e) => handleRetentionChange(e, 'monthly')}
                        />
                    </>
                )}

                <Input
                    name={`${fieldName}.retention.yearly`}
                    type="number"
                    isEditable
                    defaultValue={yearly}
                    min={
                        meta.value.type === WorkloadEnum.ARCHIVEFSNAS
                            ? ARCHIVE_MINIMUM_RETENTION_INPUT_NUMBER
                            : MINIMUM_RETENTION_INPUT_NUMBER
                    }
                    max={MAXIMUM_RETENTION_INPUT_NUMBER}
                    disabled={meta.value.type === 'select'}
                    onValueChange={(e) => handleRetentionChange(e, 'yearly')}
                />
                <Select
                    name={`${fieldName}.ltr`}
                    options={ltrOptions}
                    disabled={LTRDisabledWorkloadType.includes(meta.value.type) || !isRetentionOverAYear()}
                />
            </div>
        </>
    );
});

export default SourceFields;
