import * as React from 'react';
import { Dialog as MuiDialog, DialogTitle, DialogContent } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { useDialogContext } from 'config/context/dialogContext';
import './dialog.scss';

const Dialog = (): JSX.Element => {
    const { title, isOpen, content, close } = useDialogContext();

    return (
        <MuiDialog
            open={isOpen}
            onClose={() => close()}
            aria-labelledby="dialog-title"
            aria-describedby="dialog-description"
            classes={{
                container: 'dialog',
            }}
        >
            {title && <DialogTitle id="dialog-title">{title}</DialogTitle>}
            <DialogContent>
                <Close className="dialog-close-icon" color="disabled" onClick={() => close()} />
                <div className="dialog-content">{content}</div>
            </DialogContent>
        </MuiDialog>
    );
};

export default Dialog;
