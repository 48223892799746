import React, { useEffect, useState } from 'react';
import Table from 'generic/Table';
import 'views/ResultsTabs/Summary/SourceSummaryTable/SourceSummaryTable.scss';
import SectionLabel from 'components/SectionLabel/SectionLabel';
import { useRootState } from 'store/StateProvider';
import { useDialogContext } from 'config/context/dialogContext';
import Sources from 'views/Sources/Sources';
import { workloadTypeAliases } from 'utils/utils';
import { columns } from './columns';
import { WorkloadTypesEnum } from '../../../../types';

const SourceSummaryTable = (): React.ReactElement => {
    const [sourcesTableData, setSourcesTableData] = useState([]);
    const {
        sourcesData: { sources },
    } = useRootState();
    const { open } = useDialogContext();

    useEffect(() => {
        setSourcesTableData(
            sources.map((source, index) => ({
                ...source,
                type: WorkloadTypesEnum[source.type.toUpperCase()],
                index: `${index + 1}.`,
                ltr: source.ltr ? 'Yes' : 'No',
                dtc: source.dtc ? 'Yes' : 'No',
                ...(WorkloadTypesEnum[source.type.toUpperCase()] === workloadTypeAliases.archiveFsNas
                    ? {
                          retention: {
                              daily: 'N/A',
                              weekly: 'N/A',
                              monthly: 'N/A',
                              yearly: source.retention.yearly,
                          },
                      }
                    : {}),
            })),
        );
    }, [sources]);

    return (
        <div className="sourceSummaryTableWrapper">
            <SectionLabel>Sources</SectionLabel>
            <button
                type="button"
                className="source-table-edit"
                onClick={() => open(<Sources isEditDialog />, 'Sources')}
            >
                Edit
            </button>

            <Table data={sourcesTableData} columns={columns} rowKey="id" tableClassName="sourceSummaryTable" />
        </div>
    );
};

export default SourceSummaryTable;
