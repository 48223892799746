import React from 'react';
import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar, LabelList, Label } from 'recharts';
import _ from 'lodash';
import { useCurrentTableData } from 'hooks/useCurrentTableData';
import RenderLegend from 'views/ProgressGraphs/RenderLegend';
import { SummaryGraphHookType, SummaryGraphsTransformArgs } from 'types';
import { usePdfDataContext } from 'config/context/pdfDataContext';
import { useRootState } from 'store/StateProvider';
import { SUMMARY_GRAPH_CLASSES_MAPPING_YEAR } from 'config/setup/constants';
import { calculateYearlyDataGrow } from '../helpers';
import './summaryGraph.scss';

const transformForSummaryGraph: SummaryGraphsTransformArgs<SummaryGraphHookType> = (
    el: number,
    index,
    results,
    isDell = false,
) => ({
    year: Math.ceil((index + 1) * 100) / 100,
    'Average Source+Changes (TB)': el,
    [`Average ${isDell ? '' : 'Phoenix '}Storage (TB)`]: calculateYearlyDataGrow(
        results.allSources.monthlyData,
        'target',
    )[index],
});

const LegendCompoent = (payload) => <RenderLegend payload={payload} />;

const SummaryGraph = (): React.ReactElement => {
    const {
        location: { isDell },
        sourcesData: { tcoYear },
    } = useRootState();
    const WRAPPER_CLASS =
        _.indexOf(SUMMARY_GRAPH_CLASSES_MAPPING_YEAR.FLEX_ROW, tcoYear) === -1
            ? SUMMARY_GRAPH_CLASSES_MAPPING_YEAR.COL_WRAPPER_CLASS
            : SUMMARY_GRAPH_CLASSES_MAPPING_YEAR.ROW_WRAPPER_CLASS;
    const { timeRangeInSummaryGraph, graphCompleted } = usePdfDataContext();
    const { currentSourceData } = useCurrentTableData<SummaryGraphHookType>(
        1,
        0,
        'monthlyData',
        null,
        transformForSummaryGraph,
        timeRangeInSummaryGraph,
        isDell,
    );

    return (
        <div className={WRAPPER_CLASS}>
            <BarChart
                width={SUMMARY_GRAPH_CLASSES_MAPPING_YEAR.default.BARCHART_WIDTH}
                height={SUMMARY_GRAPH_CLASSES_MAPPING_YEAR.default.BARCHART_HEIGHT}
                data={currentSourceData}
                margin={{ bottom: 20 }}
            >
                <CartesianGrid strokeDasharray="6 6" vertical={false} />
                <XAxis dataKey="year" axisLine={false} tickLine={false} tickMargin={20}>
                    <Label value="Year" style={{ fill: '#000' }} offset={-5} position="insideBottomLeft" />
                </XAxis>
                <YAxis axisLine={false} tickLine={false} tickMargin={20}>
                    <Label value="TB" style={{ fill: '#000' }} offset={20} position="bottom" />
                </YAxis>
                {graphCompleted ? <Tooltip /> : null}
                <Legend
                    verticalAlign="top"
                    wrapperStyle={{ top: 0 }}
                    height={70}
                    content={({ payload }) => LegendCompoent(payload)}
                />
                <Bar
                    dataKey="Average Source+Changes (TB)"
                    fill="rgba(20,110,180,1)"
                    isAnimationActive={false}
                    maxBarSize={75}
                >
                    <LabelList dataKey="Average Source+Changes (TB)" position="top" style={{ fill: '#000' }} />
                </Bar>
                <Bar
                    dataKey={`Average ${isDell ? '' : 'Phoenix '}Storage (TB)`}
                    fill="#FF9900"
                    maxBarSize={75}
                    isAnimationActive={false}
                >
                    <LabelList
                        dataKey={`Average ${isDell ? '' : 'Phoenix '}Storage (TB)`}
                        position="top"
                        style={{ fill: '#000' }}
                    />
                </Bar>
            </BarChart>
        </div>
    );
};

export default SummaryGraph;
