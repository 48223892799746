import React, { useState, useEffect } from 'react';
import Table from 'generic/Table';
import { useRootState } from 'store/StateProvider';
import { createTableObj } from './helpers';
import { columns } from './summaryTableColumns';
import './summaryTable.scss';

const SummaryTable = (): React.ReactElement => {
    const { calculatedData } = useRootState();
    const [tableData, setTableData] = useState([]);
    useEffect(() => {
        if (calculatedData.results) {
            const {
                results: { ltr, noLtr, dtc, allSources },
            } = calculatedData;

            const data = allSources.monthlyData.map((el, index) => ({
                month: index + 1,
                year: Math.ceil((index + 1) / 12),
                ltr: createTableObj(ltr.monthlyData, index),
                dtc: createTableObj(dtc.monthlyData, index),
                noLtr: createTableObj(noLtr.monthlyData, index),
                allSources: createTableObj(allSources.monthlyData, index),
            }));

            setTableData(data);
        }
    }, [calculatedData]);

    return (
        <div className="summaryTableWrapper">
            <Table data={tableData} columns={columns} rowKey="month" tableClassName="summaryTable" />
        </div>
    );
};

export default SummaryTable;
