import React from 'react';

const makeStyles = (color: string) => ({
    display: 'inline-block',
    width: '2rem',
    height: '2rem',
    background: `${color}`,
    borderRadius: '3px',
    marginRight: '1.6rem',
    marginLeft: '0.8rem',
});
const RenderLegend = ({ payload }: { payload: { value: string; color: string }[] }): JSX.Element => (
    <ul className="list">
        {payload.map(({ value, color }: any) => (
            <li key={value} className="list__item">
                <span style={makeStyles(color)} />
                <span>{value}</span>
            </li>
        ))}
    </ul>
);

export default RenderLegend;
