import React, { memo, useCallback } from 'react';
import { Toolbar } from '@material-ui/core';
import 'components/Navbar/navbar.scss';
import IconButton from 'components/IconButton/IconButton';
import assumptionsIcon from 'assets/assumptions-icon.png';
import { useRootDispatch } from 'store/StateProvider';
import { resetSteps } from 'store/slices/actions';
import { usePdfDataContext } from 'config/context/pdfDataContext';
import Logo from 'components/Logo/Logo';

import { openDrawer } from 'store/slices/assumptions/assumptionsActions';
import AssumptionsDrawer from 'components/AssumptionsDrawer/AssumptionsDrawer';
import AccountDetailsPopover from './AccountDetailsPopover';

const Navbar = memo(() => {
    const dispatch = useRootDispatch();
    const { changeTabIndex } = usePdfDataContext();

    const open = useCallback(() => {
        dispatch(openDrawer());
    }, [dispatch]);

    const handleHomeClick = () => {
        changeTabIndex(0);
        dispatch(resetSteps());
    };
    return (
        <nav className="navbar">
            <div className="navbar__logo">
                <Toolbar onClick={handleHomeClick} className="toolbar">
                    <Logo />
                    <p className="navbar__text">Sizing Tool</p>
                </Toolbar>
            </div>

            <div className="navbar__buttons">
                <IconButton icon={assumptionsIcon} text="Assumptions" onClick={open} />
                <AccountDetailsPopover />
            </div>
            <AssumptionsDrawer />
        </nav>
    );
});

export default Navbar;
