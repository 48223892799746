import { getType, ActionType } from 'typesafe-actions';
import * as Actions from './calculatedDataActions';
import { calculatedDataInitialState } from './calculatedData.initialState';

const { updateCalculatedData } = Actions;

export const calculatedDataReducer = (
    state: CalculatedDataState,
    action: CalculatedDataActionTypes,
): CalculatedDataState => {
    switch (action.type) {
        case getType(updateCalculatedData):
            return { ...state, ...action.payload };
        default: {
            return state;
        }
    }
};
export type CalculatedDataActionTypes = ActionType<typeof Actions>;
export type CalculatedDataState = typeof calculatedDataInitialState;
